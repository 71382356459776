import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { notification } from 'antd';
import { API_URI } from './config';

const httpLink = new HttpLink({ uri: API_URI });

const authLink = setContext((_, { headers }) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  return { headers: { ...headers, Authorization: isLoggedIn } };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      notification.error({
        message: `${error.code}: ${error.message}`,
        description: JSON.stringify(error.data, null, 2),
      });
    });

    const hasToken = Boolean(localStorage.getItem('isLoggedIn'));
    if (
      hasToken &&
      graphQLErrors &&
      graphQLErrors.some(({ code }) => code === 'UNAUTHENTICATED')
    ) {
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('authType');
      window.location.reload();
    }
  }

  if (networkError) {
    notification.error({
      message: 'NETWORK ERROR',
      description: networkError,
    });
  }
});

const cache = new InMemoryCache();

const defaultOptions = {
  watchQuery: {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
};

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache,
  defaultOptions,
});

export default client;
