import React, { useState, useEffect, useCallback } from 'react';
import { PageHeader, Col, Row, Space, Typography, Button, Form, Divider } from 'antd';
import qs from 'querystring';
import moment from 'moment';
import axios from '../../../../utils/custom-axios';
import { Admin } from '../../../templates2';
import { EditableTable, SimpleForm } from '../../../molecules';
import { API_URI } from '../../../../config';
import sendNotification from '../../../../utils/sendNotification';
import errorHandler from '../../../../utils/errorHandler';
const { Text } = Typography;

function ApproverAdhocTxnList() {
  const [searchQuery, setSearchQuery] = useState();
  const [selected, setSelected] = useState({ keys: [], records: [] });
  const [isFetching, setFetching] = useState(false);
  const [shipments, setOrders] = useState([]);
  const [jobStatuses, setJobStatuses] = useState([]);
  const [form] = Form.useForm();
  const [hubs, setHubs] = useState([]);
  const [locations, setLocations] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentLimit, setCurrentLimit] = useState(100);
  const [total, setTotal] = useState(0);
  var date = new Date();
  const [todayDate, setTodayDate] = useState(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
  const [bodyData, setBodyData] = useState({ 'page': currentPage, 'limit': currentLimit });

  const getOrders = useCallback((filterData = bodyData, params = null) => {

    console.log("heyeyy");
    let data = '',
      apiUrl = `${API_URI}/approver/v1/adhoc_transactions`;
    if (params) {
      const { variables } = params;
      apiUrl += '?' + qs.stringify(variables);
    }
    setFetching(true);
    console.log(filterData);
    setBodyData(filterData);
    console.log(apiUrl);
    let authorization = localStorage.getItem("jwtToken");

    axios.get(apiUrl, { headers: { 'Content-type': 'application/json;  charset=utf-8', 'Authorization': authorization } })
      .then((response) => {

        console.log(response);

        if ("data" in response && "data" in response.data) {
          console.log(response.data.data.page);
          setCurrentPage(response.data.data.page);
          setTotal(response.data.data.total_count);
          console.log(response.data)
          setOrders(response.data.data);
          setFetching(false);
        }
      })
      .catch(async (error) => {
        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Txns', '', 'error');
      })
  }, []);

  useEffect(() => {
  }, [getOrders]);


  useEffect(() => {
    let authorization = localStorage.getItem('jwtToken');
    function getTxns() {
      let data = '',
        apiUrl = `${API_URI}/approver/v1/adhoc_transactions`;
      setFetching(true);
      console.log(apiUrl);
      axios.get(apiUrl, { headers: { 'Content-type': 'application/json;  charset=utf-8', 'Authorization': authorization } })
        .then((response) => {

          console.log(response);
          if ("data" in response && "data" in response.data) {
            console.log(response.data.data.page);
            setCurrentPage(response.data.data.page);
            setTotal(response.data.data.total_count);

            setOrders(response.data.data);
            setFetching(false);

          }
        }).catch(async (error) => {

          console.log(error);

          await errorHandler(error);
          setFetching(false);
          sendNotification('Failed to fetch Txns', '', 'error');
        })

    }
    getTxns();
  }, [getOrders]);

  const handlePageChange = (page, limit) => {
    setCurrentLimit(limit);
    var data = bodyData;
    data['page'] = page;
    data['limit'] = limit;
    getOrders(hubs, data);
    setBodyData(data);
    setSelected({ keys: [], records: [] });
  };


  const handleSelectRecord = (selectedRowKeys, selectedRows) => {
    console.log(selectedRowKeys);
    console.log(selectedRows);
    if (selectedRowKeys.length > 1) {
      sendNotification("Please approve the previous selection", "operation not permitted", "error")
    } else {

      setSelected({ keys: selectedRowKeys, records: selectedRows });
    }
  };

  const handleApprove = async (tableData) => {

    let apiUrl = `${API_URI}/approver/v1/send_mail`;
    var createLocationData = tableData;
    console.log(createLocationData);
    let authorization = localStorage.getItem('jwtToken');
    axios.post(apiUrl, selected['keys'], { headers: { 'Content-type': 'application/json; charset=utf-8', 'Authorization': authorization } })
      .then((response) => {
        getOrders();
        sendNotification("Txn processed", "", "success");
      }).catch(err => {
        console.log(err);
        sendNotification("Txn not processed", "", "error");
      });
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '100px',

      sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      sortDirections: ['descend'],
    },
    {
      title: 'Transaction Ref num',
      dataIndex: 'reference_number',
      key: 'reference_number',
      width: '100px',

      sorter: (a, b) => parseInt(a.reference_number) - parseInt(b.reference_number),
      sortDirections: ['descend'],
    },
    {
      title: 'WareHouse',
      dataIndex: 'warehouse',
      key: 'warehouse',
      width: '100px',
    },
    {
      title: 'Reference Id',
      dataIndex: 'request_id',
      key: 'request_id',
      width: '100px',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '100px',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: '100px',
    },
    {
      title: 'File',
      dataIndex: 'file_name',
      key: 'file',
      width: '100px',
      render: (text, record) => (
        // <Space size="middle">
        <a href={record.file_name} target="_blank">file </a>
        // {/* <a>Delete</a> */}
        // </Space>
      ),
    },
    {
      title: 'barcode',

      key: 'file',
      width: '200px',
      dataIndex: 'barcode_image',
      render: (text, record) => <img style={{ width: 200 + "px", height: 100 + "px" }} src={record.barcode_image} />
    },

    {
      title: 'Action',
      key: 'operation',

      fixed: 'right',
      width: '100px',
    },
  ];



  const inputs = [
    {
      name: 'date',
      label: 'Date',
      type: 'datepicker',
      rules:
        [
          {
            required: false,
            message: 'Scheduled End is required'
          }
        ],
      hasFeedback: true,
      col: true,
      defaultValue: moment(),
    },
    {
      name: 'status',
      label: 'Status',
      rules:
        [
          {
            required: false,
            message: 'Section Type is required'
          }
        ],
      options: jobStatuses,
      hasFeedback: true,
      type: 'dynamic-tag',
      col: true,
    }
  ];

  const onApprove = async () => {
    if (selected['keys'].length == 0) {
      sendNotification("please select to approve", "", "error")
    }

    handleApprove();
  };

  return (
    <Admin sidenav="approver-orders">
      <PageHeader
        title="Adhoc Transactions"

      >

        <Row gutter={16}>
          <Col span={24}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={onApprove}
              style={{ marginLeft: '2%' }}
            >
              Approve
            </Button>
          </Col>
        </Row>
        <Divider />
      </PageHeader>

      <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
        <EditableTable
          // rowKey="shipment_id"
          rowKey="request_id"
          rowClassName={() => 'editable-row'}
          columns={columns}
          dataSource={shipments}
          loading={isFetching}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: currentLimit,
            defaultCurrent: 1,
            total: total,
            onChange: handlePageChange,
          }}
          // onChange={handlePageChange}
          size="small"
          scroll={{ x: '1600px' }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selected.keys,
            onChange: handleSelectRecord,
          }}
        />
      </div>

    </Admin>
  );
}

export default ApproverAdhocTxnList;

