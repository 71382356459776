import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Divider, Modal, Select, Typography } from 'antd';

import moment from 'moment';
import axios from '../../../../utils/custom-axios';
import { SimpleForm } from '../../../molecules';
import { API_URI } from '../../../../config';
import errorHandler from '../../../../utils/errorHandler';
import { Input, Space, Dropdown } from 'antd';

import { SearchOutlined, CaretDownFilled, DeleteFilled, PlusOutlined, EyeFilled, MailFilled, DownOutlined, EditFilled } from '@ant-design/icons';
import { TruckList } from '..';

import sendNotification from '../../../../utils/sendNotification';
const { Option } = Select;
function TruckLoadingForm({ open, setOpenForm, truckRec, onComplete, closeModal }) {
    ;
    console.log("open")
    console.log(open);
    console.log(truckRec)
    const [fetching, setFetching] = useState(true);
    const [loadingBay, setLoadingBay] = useState([]);
    const [driverName, setDriverName] = useState([]);
    const [_isvisible,setisVisible] = useState(false);
    const [_truckType, _setTruckType] = useState([]);
    const [_truckValue, setTruckValue] = useState([]);
    const [_platenumber, _setplatenumber] = useState([]);
    const [plateValue, setPlateValue] = useState([]);
    const [addPlate, setAddPlate] = useState([]);
    const [_loadingBay, _setLoadingBay] = useState([]);
    const [selectPlateNumber, setSelectPlateNumber] = useState([]);
    const [_trucker, _setTrucker] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    const [truckerInfo, setTruckerInfo] = useState([]);
    const [truckerList, setTruckerList] = useState([]);
    const [store, setStore] = useState([]);
    const [Bay, setBay] = useState([]);
    const [plateNumberList, setPlateNumberList] = useState([]);
    const [truckTypeList, setTruckTypeList] = useState([]);
    const [storeResponse, setStoreResponse] = useState([]);
    const truckTypes = ["6W", "10W", "FWD"]
    const [state, setState] = useState({ truckerInfo: [], truckerList: [], plateNumberList: [] });
    ;
    var resp;
    var _flag =false;
    const getTruckDetails = () => {

        let data = '',
            apiUrl = `${API_URI}/admin/v1/trucker_info`;
        let authorization = localStorage.getItem("jwtToken");

        axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
            .then((response) => {

                console.log(response);

                ;
                if ("data" in response && "data" in response.data) {
                    resp = response.data.data.data;
                    setStoreResponse(resp);

                    // setTruckerInfo(resp);
                    var localTruckList = [];
                    var localPlateList = [];
                    for (var i = 0; i < resp.length; i++) {
                        if (!localTruckList.includes(resp[i].company_name)) {
                            localTruckList.push(resp[i].company_name);
                        }
                        ;
                        localPlateList.push(resp[i].plate_number)
                    }
                    console.log(localTruckList);
                    setCompanyName(localTruckList)
                    _setplatenumber(localPlateList);
                    console.log("localTruckList");
                    ;
                    var arr = [];
                    for (var i = 0; i < localTruckList.length; i++) {

                        var warehouseId = {
                            'id': localTruckList[i],
                            "value": localTruckList[i],
                            "label": localTruckList[i]
                        }
                        arr.push(warehouseId);
                    }
                    var arr2 = [];
                    for (var i = 0; i < localPlateList.length; i++) {
                        var warehouseId = {
                            'id': localPlateList[i],
                            "value": localPlateList[i],
                            "label": localPlateList[i]
                        }
                        arr2.push(warehouseId);
                    }
                    var arr3 = [];
                    for (var i = 1; i < 11; i++) {
                        var warehouseId = {
                            'id': "LoadingBay" + i,
                            "value": "LoadingBay" + i,
                            "label": "LoadingBay" + i
                        }
                        arr3.push("loadingBay" + i);
                    }
                    var arr4 = [];
                    for (var i = 0; i < truckTypes.length; i++) {
                        var trucks = {
                            'id': truckTypes[i],
                            "value": truckTypes[i],
                            "label": truckTypes[i]
                        }
                        arr4.push(trucks);
                    }
                    setTruckerList(arr);
                    setPlateNumberList(arr2);
                    setLoadingBay(arr3);
                    setTruckTypeList(arr4);
                    setFetching(false);
                }
            })
            .catch(async (error) => {
                ;
                await errorHandler(error);
                sendNotification('Failed to fetch Txns', '', 'error');
            })
    }
    const checkItem = (_, value) => {
        console.log(value);
        return Promise.resolve();
        // for (var i = 0; i < state.truckerInfo.length; i++) {
        //     if (state.truckerInfo[i]['item_code'] == value) {

        //         form.setFieldsValue({
        //             sku: state.truckerInfo[i]['super8_name'],
        //         });
        //         return Promise.resolve();
        //         break;
        //     }
        // }



    };
    useEffect(() => {
        getTruckDetails();
    }, []);
    const [form] = Form.useForm();
    const inputs = [
        {
            name: 'loading_bay',
            label: 'loadingBay',
            type: 'dynamicSelect',
            rules:
                [
                    {
                        required: false,
                        message: 'Scheduled End is required'
                    }
                ],
            hasFeedback: true,
            // col: true,
            options: loadingBay

        }, {
            name: 'trucker',
            label: 'trucker',
            hasFeedback: true,
            showSearch: true,
            options: truckerList,
            type: 'select',
            rules:
                [
                    { validator: checkItem, }
                ]

        }, {
            name: 'plate_number',
            label: 'plate',
            hasFeedback: true,
            showSearch: true,
            options: plateNumberList,
            type: 'select',
            rules:
                [
                    { validator: checkItem, }
                ]

        }, {
            name: 'truck_type',
            label: 'truck_type',
            hasFeedback: true,
            showSearch: true,
            options: truckTypeList,
            type: 'select',
            rules:
                [
                    { validator: checkItem, }
                ]

        }, {
            name: 'driver_name',
            label: 'driver_name',
            hasFeedback: true,

        },

    ];
    const [truckDetails, setTruckDetails] = useState([]);
    console.log("in loading 47");
    ;
    const onFormFinish = async () => {
        const { meta, ...values } = await form.validateFields();
        var addValues = {
            "loading_bay": _loadingBay,
            "trucker": _trucker,
            "plate_number": addPlate,
            "truck_type": _truckValue,
            "driver_name": driverName,
            "truck": truckRec['truck']
        };


        onComplete(addValues);

    };
    const togglePopup = () => {
        setOpenForm(!open);
        closeModal(false);
    }

    const _handlePlateChange = (e) => {
        console.log(e);
        setAddPlate(e);
        var temp = [];
        console.log(storeResponse);
        for (var i = 0; i < storeResponse.length; i++) {
            if (storeResponse[i].plate_number == e) {
                temp.push(storeResponse[i].company_name);
            }
        }
        if (temp.length > 0) {
            setCompanyName(temp);
        }



    }
    const _handleTruckerChanege  =(e) => {
        console.log(e);
        _setTrucker(e);
        var temp = [];
        console.log(storeResponse);
        for (var i = 0; i < storeResponse.length; i++) {
            if (storeResponse[i].company_name == e) {
                temp.push(storeResponse[i].plate_number);
            }
        }
        if (temp.length > 0) {
            _setplatenumber(temp);
        }

    }


    // console.log("state.showModal");
    // console.log(state.showModal);
    // okButtonProps={{ style: { display: 'none' } }}
    return (
        !fetching &&
        <Modal okButtonProps={{ style: { display: 'none' } }} onCancel={togglePopup} handleClose=
            {togglePopup} visible={open} width={500} style={{ borderRadius: "20px !important", top: 20, }} >
            {truckRec['truck']}

            <div style={{ borderRadius: "20px" }}>
                <div style={{ marginTop: 20 }}>
                    {/* {console.log(props.data)} */}
                    <p style={{ fontSize: '12px', marginTop: '10px' }}>Loading Bay</p>
                    <Select
                        style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }}
                        onChange={_setLoadingBay}
                        placeholder=""
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >

                                </Space>
                            </>
                        )}
                    >
                        {loadingBay.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
                    <p style={{ fontSize: '12px', marginTop: '10px' }}>Trucker</p>
                    <Select
                        style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }}
                        onChange={_handleTruckerChanege}
                        placeholder=""
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >

                                </Space>
                            </>
                        )}
                    >
                        {companyName.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
                    <p style={{ fontSize: '12px', marginTop: '10px' }}>Plate Number</p>
                    <Select
                        style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }}
                        onChange={_handlePlateChange}
                        placeholder=""
                        visible={_isvisible}
                        value={addPlate}
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >
                                    <Input placeholder="Please enter item" onChange={e => setAddPlate(e.target.value)} />
                                    <Typography.Link
                                         onClick={(e)=> {e.preventDefault(); _flag=true; }} 
                                        style={{
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <PlusOutlined /> Add item
                                    </Typography.Link>
                                </Space>
                            </>
                        )}
                    >
                        {_platenumber.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
                    <p style={{ fontSize: '12px', marginTop: '10px' }}>Truck Type</p>
                    <Select
                        style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }}
                        onChange={setTruckValue}
                        placeholder=""
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >

                                </Space>
                            </>
                        )}
                    >
                        {truckTypes.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
                    <p style={{ fontSize: '12px', marginTop: '10px' }}>Driver's Name</p>

                    <Input onChange={e => { setDriverName(e.target.value) }} id="searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="Sku Code" />
                </div>

                <div style={{ padding: '20px 20px' }}>
                </div>

                {/* <Button */}



                 <Button
                type="primary"
                htmlType="submit"
                onClick={onFormFinish}
                style={{   borderColor:'var(--primary-color)', padding:'5px 20px', width:'100%',  alignItems:'center' , marginTop:'5%' , backgroundColor:'#ffffff'}}
            >
                Save Loading Details
            </Button>
            {/* </div>
            


          
                add Data
            </Button> */}
            </div> 
        </Modal>);
}
export default TruckLoadingForm;