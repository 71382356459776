import React, { useState, useEffect} from 'react';
import { message,Upload,Dropdown, Space, Button, Select,Menu, Divider,Typography, Modal } from 'antd';
import axios from '../../../../utils/custom-axios';
import { API_URI } from '../../../../config';
import { DownOutlined, FundTwoTone } from '@ant-design/icons';
import sendNotification from '../../../../utils/sendNotification';
import { InboxOutlined ,PlusOutlined} from '@ant-design/icons';
import { Input } from 'antd';
import errorHandler from '../../../../utils/errorHandler';
const { Option } = Select;

const AddSku = props => {
 const [selected, setSelected] = useState({ keys: [], records: [] });
 const [isFetching, setFetching] = useState(false);
 const [isnewFieldd,setisnewField] = useState(true);
 const [storedynamicBins,setdynamicBins] = useState([]);
//  const [storeZones,setStoreZones] = useState([]);
 const [getZones,setgetZones] = useState([]);
 const [store_bins,setstore_bins] = useState([]);
 const [tempStore,settempStore] = useState([]);
 const [storeBins,setStoreBins] = useState([]);
 const [shipments, setOrders] = useState([]);
 const [locationArray,setlocationArray] = useState([]);
 const [getBins,SetBins] = useState(false);
 const [warningmsg,setwarningmsg] = useState([]);
 const [_date,setDate] = useState("Date Here");
 const [isModalVisible,setisModalVisible] =useState(true);
 const [bin_num,setBin_num] = useState("");
 const [zone_id,setZone_id] =useState("");
 const [img_file,setImg_file]=useState();
 const [selectedValue, setSelectedValue] = useState("Select Zones");
 const [sku_name,setSku_name] = useState("");
 const [case_code,setCase_code] = useState("");
 const [sku_code,setSku_code] = useState("");
 const [cbm,setCbm] = useState("");
 const [case_config,setCase_config] = useState("");
 const [pallet_config,setPallet_config] = useState("");
 const [count,setCount] = useState(0);
 const [text,setText] = useState("Add SKU");
 const [_propsData,setPropsData] = useState();
 const [bodyData, setBodyData] = useState({ 'page': 1, 'limit': 100 });
 const [bin,setBin] = useState();
 const [zone,setZone] = useState();
 const [isProps,setIsProps] = useState(false);
 var data;
 const { Dragger } = Upload;
 var _locationData = [];
 var __flag=false;
 var binArray = [];


 const getZonesList = () => {
    console.log("heyeyy");
    let data = '',
      apiUrl = `${API_URI}/admin/v1/zones`;
    let authorization = localStorage.getItem("jwtToken");
    console.log(apiUrl);

    axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);

        if ("data" in response && "data" in response.data) {
          //console.log(response.data.data);
        //   setgetmails(response.data.data);
          var len  = response.data.data.data;
          for(let i=0;i<len.length;i++) {
             
                var _store  = len[i].name;
                console.log(_store);
                const after_ = _store.substring(_store.indexOf('-') + 1);
                console.log(after_);
                getZones.push(after_);
          }
          setgetZones([...getZones]);
        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Mail Lists', '', 'error');
      });
  }



 async function getBin() {
 if(props.data!=null) { 
 console.log("Hey");
 var apiUrl = `${API_URI}/admin/v1/sku_bin?sku_code=${props.data.sku_code}`;
 let authorization = localStorage.getItem("jwtToken");
 console.log(apiUrl);
 await axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json; charset=utf-8' } })
 .then((response) => {

 console.log(response);
 if ("data" in response && "data" in response.data) {
 response.data.data.data.map((e,i)=> {
 var store = {"bin_loc":e.bin_loc,"zone_id":e.zone_id,"expires_at":e.expires_at}; 
//  locationArray.push(store);
 })
 console.log(response.data.data.data);
 setOrders(response.data.data.data);
 
 SetBins(true);
 }
 })
 .catch(async (error) => {

 await errorHandler(error);
 })
}
}

// async funtion fetchBins() {
//   return ();
// }
 
 const togglepopUp = props.handleClose;
 useEffect(() => {
 // setPropsData(props.data[0]);
 getZonesList();
 if(props.data!=null) {
 console.log("hey");
 setText("Edit SKU");
 setIsProps(true);
 setCase_code(props.data.case_code);
 setCase_config(props.data.case_config);
 setSku_code(props.data.sku_code);
 setPallet_config(props.data.pallet_config);
 setCbm(props.data.cbm);
 setSku_name(props.data.name);
 getBin();
 
 }
//  if(props.zones!=null) {
//  console.log(props.zones);
//  console.log(props.bin);
// //  setStoreZones(props.zones);
// //  setStoreBins(props.bin);
//  }
 
 }, []);
 useEffect(() => {

 }, [getBin]);
 useEffect(() => {
 
}, [setBin_num]);



 const _props = {
 name: 'file',
 
 onChange(info) {
 const { status } = info.file;
 console.log(status);
 if(info.fileList[0].originFileObj.type=="image/jpeg" || info.fileList[0].originFileObj.type=="image/jpg") {
 console.log(info.fileList[0].originFileObj);
 setImg_file(info.fileList[0].originFileObj);
 data= info.fileList[0].originFileObj;
 // setStateValue(info.fileList);
 }
 else {
 message.error("Please Select an image ");
 }
 
 },
 
 onDrop(e) {
 console.log('Dropped files', e.dataTransfer.files);
 },
 };

  async   function fetchBinLocations(id) {
  console.log(id);
  setZone_id(id);
  var apiUrl = `${API_URI}/admin/v1/sku_bin?zone_id=${id}`;
  let authorization = localStorage.getItem("jwtToken");
  console.log(apiUrl);

   axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);
        //setStoreBins(response.data.data.bin_loc);

        if ("data" in response && "data" in response.data) {
          var tempp= [];
          var _sto = [];
          console.log(response.data.data.data);
          tempp = response.data.data.data;
          for(var i=0;i<tempp.length;i++) {
            
            _sto.push(tempp[i].bin_loc);

          }
          setStoreBins(_sto);
        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch bins for corresponding zone id', '', 'error');
      });


 }
 async function getBinArray(index,id) {
   console.log(index,id);
   var apiUrl = `${API_URI}/admin/v1/sku_bin?zone_id=${id}`;
  let authorization = localStorage.getItem("jwtToken");
  console.log(apiUrl);

   axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);
        //setStoreBins(response.data.data.bin_loc);

        if ("data" in response && "data" in response.data) {
          var tempp= [];
          var _sto = [];
          console.log(response.data.data.data);
          tempp = response.data.data.data;
          for(var i=0;i<tempp.length;i++) {
            
            _sto.push(tempp[i].bin_loc);

          }
          
          
        //   setStoreBins(storeBins=>({
        //     ...storeBins,
        //     [index]: _sto
        //  }))
        // storedynamicBins[index]  = _sto;
         binArray  =  [...store_bins];
         binArray[index] = _sto;
         setstore_bins(binArray);
         console.log(store_bins);
        // setdynamicBins(storedynamicBins);
        console.log(binArray);
         console.log(storedynamicBins);
        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch bins for corresponding zone id', '', 'error');
      });

 }
 
 const _addSku = async()=> {
 
 if(bin_num!="" || zone_id!="") {
 locationArray.push({"bin_loc":bin_num,"zone_id":zone_id,expires_at:"2022-05-30"});
 }
 var store = [];
 store = locationArray;
 store = locationArray.filter(function (el) {
 return el != null;
 });
 console.log(store);
 setlocationArray(store);
 var apiUrl = `${API_URI}/admin/v1/sku`;
 let authorization = localStorage.getItem("jwtToken");
 var details = JSON.stringify({
 "sku_code": sku_code,
 "case_code": case_code,
 "name": sku_name,
 "pallet_config": pallet_config,
 "cbm": cbm,
 "case_config":case_config, 
 "bin":store
 });
 var _formdata = new FormData();
 _formdata.append("data",details);
 _formdata.append("file_data",new Blob([img_file], {type:"image/jpeg"},));
 console.log(apiUrl);

 if(props.data!=null) {
 apiUrl = `${API_URI}/admin/v1/sku`;
 var __formdata = new FormData();
 var _details = JSON.stringify({
 "sku_code": sku_code,
 "case_code": case_code,
 "name": sku_name,
 "pallet_config": pallet_config,
 "cbm": cbm,
 "case_config":case_config, 
 "bin":store,
 // "bin":[{"bin_loc":bin,"zone_id":zone,"expires_at":"2022-05-30"}],
 "id":props.data.id 
 });
 __formdata.append("data",_details);
 __formdata.append("file_data",new Blob([img_file], {type:"image/jpeg"},));
 axios.put(apiUrl,__formdata ,{ headers: { 'Authorization': authorization,'Content-Type': 'multipart/form-data' } })
 .then((response) => {

 console.log(response);
 if(response.statusText=='OK' || response.status=='200') {
 sendNotification('SKU has been Updated', '', 'success');
 setTimeout(function(){
 window.location.reload(1);
 }, 1000);
 
 }
 })
 .catch(async (error) => {

 await errorHandler(error);
 setFetching(false);
 sendNotification('Failed to fetch Txns', '', 'error');
 
 })
 }
 else {
 //var warning_flag = false;
 setwarningmsg([]);
 if(sku_code=="") {
 warningmsg.push("Please enter SKU Code");
 }
 if(sku_name=="")
 warningmsg.push("Please enter SKU Name");
 if(case_code=="")
 warningmsg.push("Please enter Case Code");
 if(cbm=="")
 warningmsg.push("Please enter CBM");
 if(case_config=="")
 warningmsg.push("Please enter Case Configuration");
 if(pallet_config=="")
 warningmsg.push("Please enter Pallet Configuration");
//  if(img_file==null)
//  warningmsg.push("Please select an Image");
 
 if(warningmsg.length!=0) {
 for(let i=0;i<warningmsg.length;i++) {
 let text = "";
 text = warningmsg[i];
 message.error(text);
 }
 return ;
 }
 var _result=axios.post(apiUrl,_formdata ,{ headers: { 'Authorization': authorization,'Content-Type': 'multipart/form-data' } })
 .then((response) => {

 console.log(response);
 if(response.statusText=='OK' || response.status=='200') {
 sendNotification('SKU has been Added', '', 'success');
 setTimeout(function(){
 window.location.reload(1);
 }, 1000);
 }
 if(response.data=="sku already exists" && response.code=='6100') {
 sendNotification('SKU Already Exists', '', 'error');
 }
 })
 .catch(async (error) => {

 await errorHandler(error);
 setFetching(false);
 sendNotification(''+error.response.data.data, '', 'error');
 
 })
 }
 
 }

 const _handleValues = (value,name,index) => {
 console.log(value,name,index);
 if(locationArray[index]==null)
 {
 locationArray[index]= {"bin_loc":"","zone_id":"","expires_at":"2022-05-30"};
 }
 if(value=="removed_index") {
 //locationArray.splice(index,1);
 delete locationArray[index];
 locationArray.filter(function (el) {
 return el != null;
 });
 setlocationArray(locationArray);
 console.log(locationArray);
 return;
 }

 
 if(name=="bin_loc") {
 var x = locationArray[index]["bin_loc"] = value;
 } 
 else {
 var y = locationArray[index]["zone_id"] = value;
 }
 console.log(locationArray);
 

 }

 function handleMenuClick(e) {
 setSelectedValue(e.key);
 }

 return (
 <>
 <Modal okButtonProps={{ style: { display: 'none' } }} visible={isModalVisible} onCancel={togglepopUp}width={500} style={{ borderRadius: "20px !important", top: 20 }} >

 {isProps && getBins &&
 <>
 <div style={{ borderRadius: "20px" }}>
 <form method="post">
 <div style={{ padding:'20px 20px'}}>
 <p style={{ fontWeight: 700, size: 24 }}>{text}</p>
 <div style={{marginTop:20}}>
 {/* {console.log(props.data)} */}
 
 <p style={{fontSize:'12px' , marginTop:'10px'}}>Sku Code</p>
 <Input defaultValue={props.data.sku_code} required onChange={(event)=>{setSku_code(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="Sku Code" />
 </div>
 <p style={{fontSize:'12px', marginTop:'10px'}}>Case Code</p>
 <Input defaultValue={props.data.case_code} required onChange={(event)=>{setCase_code(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="Case Code" />
 <p style={{fontSize:'12px', marginTop:'10px'}}>Sku Name</p>
 <Input defaultValue={props.data.name} required onChange={(event)=>{setSku_name(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="Sku Name" />
 <p style={{fontSize:'12px', marginTop:'10px'}}>CBM</p>
 <Input defaultValue={props.data.cbm} required onChange={(event)=>{setCbm(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="CBM" />
 <p style={{fontSize:'12px', marginTop:'10px'}}>Case Configuration</p>
 <Input defaultValue={props.data.case_config} required onChange={(event)=>{setCase_config(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="Case Configuration" />
 <p style={{fontSize:'12px', marginTop:'10px'}}>Pallet Configuration</p>
 <Input defaultValue={props.data.pallet_config} required onChange={(event)=>{setPallet_config(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="Pallet Configuration" />
 <div style={{ display:'flex',justifyContent:'space-between'}}>
 <p style={{fontSize:'12px', marginTop:'10px'}}>Zone ID</p>
 <p style={{fontSize:'12px', marginTop:'10px'}}>Bin Number</p>
 
 </div>
 {
 shipments.map((e,i)=> 
 
 <div key={i} style={{ marginTop:'3%', display:'flex',justifyContent:'space-between'}}>
  <Input disabled={true} defaultValue={e.zone_id} id = "searchBox" style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16,  background: '#ffffff', }} placeholder="zone" />
 <Input disabled={true} defaultValue={e.bin_loc} id = "searchBox" style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16,marginLeft:40, background: '#ffffff', }} placeholder="Bin Number" />
 
 {/* <span onClick={()=>{document.getElementById("_div"+i).style.display="none"; _handleValues("removed_index","",i) }} style={{ fontWeight:500, marginTop:'4px', fontSize:'12px' , color:'var(--primary-color)'}}> <a>X</a> </span> */}
 </div>
 )
 }
 
 <div style={{ marginTop:'3%'}}>
  <div style={{display:'flex',justifyContent:'space-between'}}>
 {/* <Input defaultValue={bin} onChange={(event)=>{setBin_num(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, background: '#ffffff', }} placeholder="Bin Number" />
 <Input defaultValue={zone} onChange={(event)=>{setZone_id(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, marginLeft:40, background: '#ffffff', }} placeholder="zone" /> */}
 <Select
                        style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, background: '#ffffff', }}
                        // style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, marginLeft:40, background: '#ffffff', }}
                        onChange={(event)=>{setZone_id(event);console.log(event);fetchBinLocations(event)}}
                        defaultValue={zone}
                        placeholder="zone"
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >

                                </Space>
                            </>
                        )}
                    >
                        {getZones.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
 <Select
                        style={{  padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, background: '#ffffff', }}
                        // style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, marginLeft:40, background: '#ffffff', }}
                        onChange={(event)=>{setBin_num(event);console.log(event); }}
                        defaultValue={bin}
                        placeholder="Bin Number"
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >
                                  <Input placeholder="Please enter item" onChange={(e) => {setBin_num(e.target.value);settempStore(e.target.value)}} />
                                    <Typography.Link
                                         onClick={(e)=> {setStoreBins([tempStore])}}  
                                        style={{
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <PlusOutlined /> Add item
                                    </Typography.Link>

                                </Space>
                            </>
                        )}
                    >
                        {storeBins.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
                    
                </div>
 { isnewFieldd && [...Array(count)].map((x, i) =>
 
 <div id= {"_div"+i} key={i} style={{ marginTop:'3%', display:'flex',justifyContent:'space-between'}}>
 {/* <Input onChange={(event)=>{_handleValues(event.target.value,"bin_loc",i) }} id = "searchBox" style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, background: '#ffffff', }} placeholder="Bin Number" />
 <Input onChange={(event)=>{ _handleValues(event.target.value,"zone_id",i)}} id = "searchBox" style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, marginLeft:40, background: '#ffffff', }} placeholder="zone" /> */}
 
 <Select
                        style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, background: '#ffffff', }}
                        // style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, marginLeft:40, background: '#ffffff', }}
                        onChange={(event)=> { getBinArray(i,event);_handleValues(event,"zone_id",i);}}
                        placeholder="zone"
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >

                                </Space>
                            </>
                        )}
                    >
                        {getZones.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
 
 <Select
                        style={{  padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, background: '#ffffff', }}
                        // style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, marginLeft:40, background: '#ffffff', }}
                        onChange={(event)=> { _handleValues(event,"bin_loc",i)}}
                        placeholder="Bin Number"
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >
                                  <Input type="text" id="custom_input" placeholder="Please enter item" onChange={(e) => {_handleValues(e.target.value,"bin_loc",i);settempStore(e.target.value)}} />
                                    <Typography.Link
                                         onClick={()=> {
                                           var sto  = [...store_bins];
                                           sto[i]  = tempStore;
                                           setstore_bins(sto);

                                         }} 
                                        style={{
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <PlusOutlined /> Add item
                                    </Typography.Link>


                                </Space>
                            </>
                        )}
                    >
                           {store_bins.map(function (nested,index) {
                         // debugger;
                          // console.log(i);
                          // console.log(index,nested);
                          
                         if(index==i) {
                           
                           var typ = typeof store_bins[index];
                           console.log(typ)
                           if(typ=='string')
                            return <Option key={nested}>{nested}</Option>
                         return nested.map(function (element) {
                        return(
                          <Option key={element}>{element}</Option>
                        );
                         
                        });
}})}
                    </Select>
      
      
       

 
 
 <span onClick={()=>{document.getElementById("_div"+i).style.display="none"; _handleValues("removed_index","",i) }} style={{ fontWeight:500, marginTop:'4px', fontSize:'12px' , color:'var(--primary-color)'}}> <a>X</a> </span>
 </div>
 )}
 <span onClick={()=>{setCount(count+1); setisnewField(true)}} style={{ display:"block",fontWeight:500, marginTop:'4px', fontSize:'12px' , color:'var(--primary-color)'}}> <a>+ Add Another Location </a> </span>
 
 <p style={{fontSize:'12px', marginTop:'10px'}}>Upload Photo</p>
 <Dragger {..._props}>
 <p className="ant-upload-drag-icon">
 <InboxOutlined />
 </p>
 <p className="ant-upload-text">Click or drag file to this area to upload</p>
 
 </Dragger>
 <Button type="primary" style={{marginTop:'5%'}} onClick={()=>{_addSku()}}>Submit</Button>
 </div>
 </div>
 
 
 
 <Divider />
 </form>
 </div>
 
 </>

 }
 {!isProps && 
 <>
 <div style={{ borderRadius: "20px" }}>
 <form method="post">
 <div style={{ padding:'20px 20px'}}>
 <p style={{ fontWeight: 700, size: 24 }}>{text}</p>
 <div style={{marginTop:20}}>
 {/* {console.log(props.data)} */}
 
 <p style={{fontSize:'12px' , marginTop:'10px'}}>Sku Code</p>
 <Input required onChange={(event)=>{setSku_code(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="Sku Code" />
 </div>
 <p style={{fontSize:'12px', marginTop:'10px'}}>Case Code</p>
 <Input required onChange={(event)=>{setCase_code(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="Case Code" />
 <p style={{fontSize:'12px', marginTop:'10px'}}>Sku Name</p>
 <Input required onChange={(event)=>{setSku_name(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="Sku Name" />
 <p style={{fontSize:'12px', marginTop:'10px'}}>CBM</p>
 <Input required onChange={(event)=>{setCbm(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="CBM" />
 <p style={{fontSize:'12px', marginTop:'10px'}}>Case Configuration</p>
 <Input required onChange={(event)=>{setCase_config(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="Case Configuration" />
 <p style={{fontSize:'12px', marginTop:'10px'}}>Pallet Configuration</p>
 <Input required onChange={(event)=>{setPallet_config(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '100%', gap: 16, background: '#ffffff', }} placeholder="Pallet Configuration" />
 <div style={{ display:'flex',justifyContent:'space-between'}}>
 <p style={{fontSize:'12px', marginTop:'10px'}}>Zone ID</p>
 <p style={{fontSize:'12px', marginTop:'10px'}}>Bin Number</p>
 
 </div>

 {/* <div style={{ display:'flex',justifyContent:'space-between'}}>
 <span style={{fontSize:'10px', marginTop:'6px'}}>Zone ID</span>
 <span style={{fontSize:'10px', marginTop:'6px'}}>Bin Number</span>
 </div> */}
 <div style={{ display:'flex',justifyContent:'space-between'}}>
 
 <Select
                        style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, background: '#ffffff', }}
                        // style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, marginLeft:40, background: '#ffffff', }}
                        onChange={(event)=>{setZone_id(event);console.log(event);fetchBinLocations(event)}}
                        placeholder="zone"
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >

                                </Space>
                            </>
                        )}
                    >
                        {getZones.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
 <Select
                        style={{  padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, background: '#ffffff', }}
                        // style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, marginLeft:40, background: '#ffffff', }}
                        onChange={(event)=>{setBin_num(event);console.log(event)}}
                        placeholder="Bin Number"
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >
                                  <Input placeholder="Please enter item" onChange={(e) => {setBin_num(e.target.value);settempStore(e.target.value)}} />
                                    <Typography.Link
                                         onClick={(e)=> {setStoreBins([tempStore])}} 
                                        style={{
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <PlusOutlined /> Add item
                                    </Typography.Link>

                                </Space>
                            </>
                        )}
                    >
                        {storeBins.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
                    
                    
 {/* <Input onChange={(event)=>{setBin_num(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, background: '#ffffff', }} placeholder="Bin Number" />
 <Input onChange={(event)=>{setZone_id(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, marginLeft:40, background: '#ffffff', }} placeholder="zone" /> */}
 </div> 
 { isnewFieldd &&  [...Array(count)].map((x, i) =>
 <div id= {"_div"+i} key={i} style={{ marginTop:'3%', display:'flex',justifyContent:'space-between'}}>
 {/* <Input onChange={(event)=>{_handleValues(event.target.value,"bin_loc",i) }} id = "searchBox" style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, background: '#ffffff', }} placeholder="Bin Number" />
 <Input onChange={(event)=>{ _handleValues(event.target.value,"zone_id",i)}} id = "searchBox" style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, marginLeft:40, background: '#ffffff', }} placeholder="zone" /> */}
      <Select
                        style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, background: '#ffffff', }}
                        // style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, marginLeft:40, background: '#ffffff', }}
                        onChange={(event)=> {getBinArray(i,event);_handleValues(event,"zone_id",i);}}
                        placeholder="zone"
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >

                                </Space>
                            </>
                        )}
                    >
                        {getZones.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
      
      <Select
                        style={{  padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, background: '#ffffff', }}
                        // style={{ display:'inline-block',padding: '8 16', height: 38, border: '1px solid #D9DBE9', borderRadius: 12, width: '45%', gap: 16, marginLeft:40, background: '#ffffff', }}
                        onChange={(event)=> { _handleValues(event,"bin_loc",i)}}
                        placeholder="Bin Number"
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    align="center"
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >
                                  <Input type="text" id="custom_input" placeholder="Please enter item" onChange={(e) => {_handleValues(e.target.value,"bin_loc",i);settempStore(e.target.value)}} />
                                    <Typography.Link
                                         onClick={()=> {
                                           var sto  = [...store_bins];
                                           sto[i]  = tempStore;
                                           setstore_bins(sto);

                                         }} 
                                        style={{
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <PlusOutlined /> Add item
                                    </Typography.Link>

                                </Space>
                            </>
                        )}
                    >
                       
                        {store_bins.map(function (nested,index) {
                         // debugger;
                          // console.log(i);
                          // console.log(index,nested);
                          
                         if(index==i) {
                           
                           var typ = typeof store_bins[index];
                           console.log(typ)
                           if(typ=='string')
                            return <Option key={nested}>{nested}</Option>
                         return nested.map(function (element) {
                        return(
                          <Option key={element}>{element}</Option>
                        );
                         
                        });
}})}
                    </Select>
      
      
       
                    <span onClick={()=>{document.getElementById("_div"+i).style.display="none"; _handleValues("removed_index","",i) }} style={{ fontWeight:500, marginTop:'4px', fontSize:'12px' , color:'var(--primary-color)'}}> <a>X</a> </span> 
 </div>

 )}
 <span onClick={()=>{setCount(count+1); setisnewField(true)}} style={{ display:"block",fontWeight:500, marginTop:'4px', fontSize:'12px' , color:'var(--primary-color)'}}> <a>+ Add Another Location </a> </span>
 <div>
 <p style={{fontSize:'12px', marginTop:'10px'}}>Upload Photo</p>
 <Dragger {..._props}>
 <p className="ant-upload-drag-icon">
 <InboxOutlined />
 </p>
 <p className="ant-upload-text">Click or drag file to this area to upload</p>
 
 </Dragger>
 <Button type="primary" style={{marginTop:'5%'}} onClick={()=>{_addSku()}}>Submit</Button>
 </div>
 </div>
 <Divider />
 </form>
 </div>
 </>
 }
 </Modal>
 </>
 );
};

export default AddSku;


