import React, { useState, useEffect, useCallback } from 'react';
import { Button, Spin, Popconfirm } from 'antd';
import moment from 'moment';
import axios from '../../../../utils/custom-axios';
import { DragSortingTable } from '../../../molecules';
import { API_URI } from '../../../../config';
import sendNotification from '../../../../utils/sendNotification';
import errorHandler from '../../../../utils/errorHandler';
import Picklist from '../common/Picklist';

function QueueList() {
    const [isFetching, setFetching] = useState(false);
    const [trucks, setTrucks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [unqueueTrucks, setUnqueueTrucks] = useState([]);
    const [bodyData, setBodyData] = useState({ 'page': 1, 'limit': 100 });
    const [lastUpdate, setQueueLastUpdate] = useState('');
    const [openTrf, setOpenTrf] = useState(false);
    const [selectedTruck, setSelectedTruck] = useState('');
    const setOnHoldTrucks = (filterData = bodyData) => {
        let data = '',
            apiUrl = `${API_URI}/admin/v1/trucks`;
        filterData['status'] = "ON_QUEUE,PENDING";
        filterData['order_by'] = "rank";
        setFetching(true);
        let authorization = localStorage.getItem("jwtToken");
        setBodyData(filterData);
        axios.get(apiUrl, { params: filterData, headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
            .then((response) => {
                if ("data" in response && "data" in response.data) {
                    setCurrentPage(response.data.data.page);
                    setTotal(response.data.data.total_count);
                    setTrucks(response.data.data.data);
                    var updatedAt = moment(response.data.data.queue_last_updated).format('MMMM DD, YYYY, HH:mm'); // June 1, 2019
                    setQueueLastUpdate(updatedAt);
                    setFetching(false);
                }
            })
            .catch(async (error) => {
                await errorHandler(error);
                setFetching(false);
                sendNotification('Failed to fetch Txns', '', 'error');
            })
    }

    const getOrders = useCallback((filterData = bodyData) => {
        setOnHoldTrucks(filterData);
    }, []);

    useEffect(() => {
    }, [getOrders]);

    useEffect(() => {
        setOnHoldTrucks();
    }, [getOrders]);

    const columns = [

        {
            title: 'Order ID',
            dataIndex: 'truck',
            key: 'truck',
            width: '100px',
        },
        {
            title: 'Warehouse',
            dataIndex: 'dst_warehouse',
            key: 'dst_warehouse',
            width: '100px',
        },

        {
            title: 'PTRF ID',
            dataIndex: 'id',
            key: 'id',
            width: '100px',
        },
        {
            title: 'Loading Date',
            dataIndex: 'loading_date',
            key: 'loading_date',
            width: '100px',
        },
        //  {
        //     title: 'start_time',
        //     dataIndex: 'start_time',
        //     key: 'start_time',
        //     width: '100px',
        // }, {
        //     title: 'status',
        //     dataIndex: 'status',
        //     key: 'status',
        //     width: '100px',
        // },
        {
            title: 'Staging Area',
            dataIndex: 'staging_area',
            key: 'staging_area',
            width: '100px',
        }, {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: '120px',
            render: (text, record) => {
                if (record.status != "REMOVED") {
                    // borderColor: 'var(--primary-color )'
                    // style={{ color: 'var(--primary-color)',  }}
                    return <div style={{ textAlign: "end" }}> <a onClick={() => {
                        viewPickList(record);
                    }}
                    >View PickList</a><br></br>


                        <Popconfirm
                            title="Are you sure to remove this truck from queue?"
                            onConfirm={() => { removeFromQueue(record) }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a style={{ color: 'red' }} href="#">Remove x</a>
                        </Popconfirm>
                        {/* <Button style={{ color: 'red' }} type="text" onClick={() => {
                            removeFromQueue(record);
                        }}
                        >Delete</Button> */}
                    </div>
                }
            },
        },

    ];


    function viewPickList(record) {
        setSelectedTruck(record);
        setOpenTrf(!openTrf);
    }

    const removeFromQueue = (record) => {
        const result = trucks.filter(word => word.id != record.id);
        setTrucks(trucks => result);
        record['status'] = "REMOVED";
        setUnqueueTrucks(unqueueTrucks => [...unqueueTrucks, record])
    }
    const inputs = [
        {
            name: 'date',
            label: 'Date',
            type: 'datepicker',
            rules:
                [
                    {
                        required: false,
                        message: 'Scheduled End is required'
                    }
                ],
            hasFeedback: true,
            col: true,
            defaultValue: moment(),
        },

    ];

    function handleQueueSave(data, defaultRank) {
        const apiUrl = `${API_URI}/admin/v1/truck_queue`;
        let authorization = localStorage.getItem("jwtToken");
        console.log(data);
        debugger;
        axios.post(apiUrl, { "data": data, "default_rank": defaultRank }, { headers: { 'authorization': authorization } }
            , { withCredentials: false })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                alert("Error occured ");
            });
        if (unqueueTrucks.length > 0) {
            ;
            confirmRemoveTruck(unqueueTrucks);
        }
    }

    function confirmRemoveTruck(data) {
        ;
        const apiUrl = `${API_URI}/admin/v1/remove_from_picking_queue`;
        let authorization = localStorage.getItem("jwtToken");
        axios.post(apiUrl, { "data": data }, { headers: { 'authorization': authorization } }
            , { withCredentials: false })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                alert("Error occured ");
            });
    }

    const togglePopup = () => {
        setOpenTrf(!openTrf);
    }

    return (
        <div>
            {
                !isFetching &&
                <div>
                    <div>
                        <p style={{ fontWeight: 600 }}>Manage Queue</p>
                        <p>Drag card to rearrange order by priority</p>
                        <i> Last Update At {lastUpdate}</i>

                    </div>
                    {/* style={{ height: 400, overflow: "scroll" }} */}
                    <div >

                        <DragSortingTable onComplete={handleQueueSave} columns2={columns} reqRemovedTrucks={[...unqueueTrucks]} dataSource={[...trucks]}></DragSortingTable>
                        {/* <DragSortingTable onComplete={handleQueueSave} columns2={columns} reqRemovedTrucks={unqueueTrucks} dataSource={trucks}></DragSortingTable> */}
                    </div>
                </div>
            }
            {/* {unqueueTrucks.length > 0
                && <div>
                    <div>
                        <p>For Deletion</p>
                    </div>
                    <DragSortingTable onComplete={confirmRemoveTruck} columns2={columns} allowUpdate={true} dataSource={[...unqueueTrucks]}>

                    </DragSortingTable>
                </div>} */}
            {
                isFetching && <div style={{
                    flex: 1, width: "100%",
                    height: 500 + "px",
                    backgroundColor: "#f0f2f5",
                    textAlign: 'center',
                    justifyContent: 'center',
                    verticalAlign: 'center',
                    padding: 150 + "px"
                }}><Spin></Spin></div>
            }
            {openTrf && <>
                <Picklist data={selectedTruck} handleClose={togglePopup} isModalVisible={openTrf}></Picklist>
            </>
            }
        </div>
    );
}

export default QueueList;
