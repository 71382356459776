import React from 'react';
import { Layout } from 'antd';
import { Logo, HeaderService, HeaderMenu } from '../../atoms';

const { Header, Content, Footer } = Layout;

function Front({ fluid = false, maxWidth = '1200px', serviceCode, children }) {
  return (
    <Layout className="layout" id="front-template">
      <Header>
        <Logo />
        <HeaderService serviceCode={serviceCode} />
        <HeaderMenu />
      </Header>
      <Content className="front-content" style={fluid ? {} : { maxWidth }}>
        {children}
      </Content>
      <Footer className="app-footer">Pick Load © GrowSari 2022</Footer>
    </Layout>
  );
}

export default Front;
