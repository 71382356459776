import React, { useCallback } from 'react';
import { Row, Col, Button, Card, Form } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import axios from '../../../utils/custom-axios';

import { FormInput } from '../../atoms';
import Center from '../../templates2/Center';
import warehouseImg from '../../../assets/warehouse-full.png';
import { API_URI } from '../../../config';

var textStyle = {
  fontFamily: 'Poppins',
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 24,
  alignItems: "center",
  textAlign: "center",
  letterSpacing: 1,
  color: "#06B6AA",
}

var loginButton = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "16px 24px",
  background: "#06B6AA",
  borderRadius: "12px",

}
const LoginForm = () => {
  const [loginForm] = Form.useForm();
  let loading = false;
  const login = useCallback((username, password) => {
    loading = true;
    const apiUrl = `${API_URI}/auth/v1/login`;
    axios.post(apiUrl, { "username": username, "password": password }, { headers: { 'Content-type': 'application/json; charset=utf-8' } }
      , { withCredentials: false })
      .then((response) => {

        console.log(response);
        alert("okay");
        localStorage.setItem('isLoggedIn', response.data.data.is_logged_in);
        localStorage.setItem('authType', response.data.data.auth_type);

        localStorage.setItem('jwtToken', response.data.data.jwt_token);
        localStorage.setItem('displayName', response.data.data.display_name);
        localStorage.setItem('emailId', response.data.data.email);
          
        window.location.reload();
      })
      .catch((error) => {
        alert("Error occured ");
      });
  }, []);

  const handleLogin = async () => {
    const { username, password } = await loginForm.validateFields();
    login(username, password);
  };

  return (
    <Form form={loginForm} onFinish={(values) => handleLogin(values)}>
      <FormInput
        name="username"
        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder="Username"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      />
      <FormInput
        name="password"
        type="password"
        style={{
          borderRadius: "16px"
        }}
        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder="Password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      />
      <Form.Item style={{ marginBottom: '5%' }}>
        <Button
          type="primary"
          loading={loading}
          onClick={handleLogin}
          style={loginButton}
          block
        >
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};


function Login() {
  return (
    <Center>
      <Row
        className="cen-login"
        type="flex"
        justify="space-around"
        align="middle"
      >
        <Col className="app-center">
          <Card style={{ backgroundColor: "#F7F7FC" }}>
            <img src={warehouseImg} alt="growsari" />
            <p style={textStyle} >Central Hub Admin</p>
            <LoginForm />
          </Card>
        </Col>
      </Row>
    </Center>
  );
}

export default Login;
