import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { validateJwt } from '../utils/validateJwt';
import { proxyPath } from '../config';
var isLoggedin = !!localStorage.getItem('isLoggedIn');

const authType = localStorage.getItem('authType');
const allowedAuth = ["CH_PICKER",
  "CH_CHECKER",
  "CH_INV_PLANNER",
  "CH_OUT_SUPERVISOR",
  "CH_INV_MANAGER",
  "CH_ADMIN",
  "CH_TRANSFER_UPLOADER"
]
function AdminRoute({ component: RouteComponent, ...rest }) {
  if (authType) {
    isLoggedin = true;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedin && authType == "CH_ADMIN" ? (
          <RouteComponent {...props} nav={rest.nav} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }}
    />
  );
}

function TransferUploaderRoute({ component: RouteComponent, ...rest }) {
  if (authType) {
    isLoggedin = true;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedin && authType == "CH_TRANSFER_UPLOADER" ? (
          <RouteComponent {...props} nav={rest.nav} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }}
    />
  );
}


function SuperVisorRoute({ component: RouteComponent, ...rest }) {

  if (authType) {
    isLoggedin = true;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedin && (authType == "CH_OUT_SUPERVISOR") ? (
          <RouteComponent {...props} nav={rest.nav} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }

      }
    />
  );
}


function InventoryRoute({ component: RouteComponent, ...rest }) {
  if (authType) {
    isLoggedin = true;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedin && authType == "CH_INV_MANAGER" || authType == "CH_INV_PLANNER" ? (
          <RouteComponent {...props} nav={rest.nav} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }}
    />
  );
}

function MerchantRoute({ component: RouteComponent, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        // props.nav = rest.nav;
        return isLoggedin && allowedAuth.includes(authType) ? (
          <RouteComponent {...props} nav={rest.nav} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
      }
    />
  );
}

function GuestRoute({ component: RouteComponent, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        // props.nav = rest.nav;

        if (isLoggedin && (authType == "FINANCE" || authType == "OPS_FINANCE")) {
          return (<Redirect
            to={{
              pathname: '/approver/adhoc_transactions',
              state: { from: props.location }
            }}
          />)
        }
        else if (isLoggedin && (authType == "CH_ADMIN")) {
          return (<Redirect
            to={{
              pathname: '/admin/trucks',
              state: { from: props.location }
            }}
          />)
        }
        else if (isLoggedin && (authType == "CH_TRANSFER_UPLOADER")) {
          return (<Redirect
            to={{
              pathname: '/transfer_uploader/trf',
              state: { from: props.location }
            }}
          />)
        }
        else if (isLoggedin && (authType == "CH_INV_MANAGER")) {
          return (<Redirect
            to={{
              pathname: '/inventory/trf',
              state: { from: props.location }
            }}
          />)
        }
        else if (isLoggedin && (authType == "CH_OUT_SUPERVISOR")) {
          return (<Redirect
            to={{
              pathname: '/supervisor/trucks',
              state: { from: props.location }
            }}
          />)
        }
        else if (isLoggedin && (authType == "CH_INV_PLANNER")) {
          return (<Redirect
            to={{
              pathname: '/inventory/trf',
              state: { from: props.location }
            }}
          />)
        }
        else {
          return (<RouteComponent {...props} nav={rest.nav} />)

        }
      }}
    />
  );
}

function PrivateRoute({ component: RouteComponent, ...rest }) {

  if (authType) {

    isLoggedin = true;
  }

  return (
    <Route

      {...rest}
      render={props =>
        isLoggedin && (authType == "CH_TEAM" || authType == "CH_PICKER") ? (
          <RouteComponent {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}


export { AdminRoute, GuestRoute, MerchantRoute, SuperVisorRoute, InventoryRoute, PrivateRoute, TransferUploaderRoute };