import React from 'react';

import { proxyPath } from './config';

const approverNavList = [
  {
    path: '/approver/adhoc_transactions',
    label: 'Transactions',
  }

];

const adminNavList = [
  {
    path: '/admin/trf',
    label: 'UPLOAD PTRF',
  },
  {
    path: '/admin/trucks',
    label: 'MANAGE PICKING QUEUE',
  },
  {
    path: '/admin/pickList',
    label: 'MANAGE ORDER',
  },
  {
    path: '/admin/loading',
    label: 'MANAGE LOADING QUEUE',
  },


  {
    path: '/admin/sku',
    label: 'MANAGE SKU',
  }
];

const adminv2NavList = [
  {
    path: '/transfer_uploader/trf',
    label: 'UPLOAD PTRF',
  }
];

const supervisorNavList = [
  {
    path: '/supervisor/trucks',
    label: 'MANAGE PICKING QUEUE',
  },
  {
    path: '/supervisor/pickList',
    label: 'MANAGE ORDER',
  },
  {
    path: '/supervisor/loading',
    label: 'MANAGE LOADING QUEUE',
  },


];

const inventoryPlannerList = [
  {
    path: '/inventory/trf',
    label: 'UPLOAD PTRF',
  },
]

const services = [
  {
    code: 'admin-trucks',
    name: 'Trucks',
    link: '/admin/trucks',
    nav: adminNavList,

  }, {
    code: 'admin-loading',
    name: 'Trucks',
    link: '/admin/loading',
    nav: adminNavList,
  },
  {

    code: 'admin-trf',
    name: 'TRF',
    link: '/admin/trf',
    nav: adminNavList,
  },
  {
    code: 'admin-picklist',
    link: '/admin/pickList',
    nav: adminNavList,
    label: 'MANAGE ORDER',
  },
  {

    code: 'transfer-uploader-trf',
    name: 'TRF',
    link: '/transfer_uploader/trf',
    nav: adminv2NavList,
  },
  {
    code: 'sku',
    link: '/admin/sku',
    nav: adminNavList,
    label: 'MANAGE SKU',
  }, {
    code: 'supervisor-trucks',
    name: 'trucks',
    link: '/supervisor/trucks',
    nav: supervisorNavList,
  },
  {
    code: 'supervisor-picklist',
    link: '/supervisor/pickList',
    nav: supervisorNavList,
    label: 'MANAGE ORDER',
  }, {
    code: 'supervisor-loading',
    name: 'Trucks',
    link: '/supervisor/loading',
    nav: supervisorNavList,
  }, {
    link: '/inventory/trf',
    label: 'UPLOAD PTRF',
    code: 'inv-trf',
    nav: inventoryPlannerList,
    label: 'MANAGE ORDER',
  },
];

export default services;
