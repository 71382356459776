import React, { useState, useEffect, useCallback } from 'react';
import { PageHeader, Col, Row, Space, Typography, Button, Form, Divider, Spin, Upload, Drawer } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import qs from 'querystring';
import moment from 'moment';
import axios from '../../../../utils/custom-axios';
import { Admin, Center } from '../../../templates2';
import { EditableTable, SimpleForm } from '../../../molecules';
import { API_URI } from '../../../../config';
import sendNotification from '../../../../utils/sendNotification';
import DragDropPtrf from './DragDropPtrf';

import errorHandler from '../../../../utils/errorHandler';
//import { margin } from '@mui/system';


function PtrfUpload(props) {
  const [selected, setSelected] = useState({ keys: [], records: [] });
  const [isFetching, setFetching] = useState(false);
  const [shipments, setOrders] = useState([]);
  const [form] = Form.useForm();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(100);
  const [total, setTotal] = useState(0);
  const [uploadedFile, setFile] = useState(null);
  var date = new Date();
  const [bodyData, setBodyData] = useState({ 'page': 1, 'limit': 100 });

  const setTrf = (filterData = bodyData) => {

    // console.log("heyeyy");
    let data = '',
      apiUrl = `${API_URI}/admin/v1/trf`;

    setFetching(true);
    console.log(filterData);
    let authorization = localStorage.getItem("jwtToken");

    setBodyData(filterData);
    console.log(apiUrl);

    axios.get(apiUrl, { params: filterData, headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);

        if ("data" in response && "data" in response.data) {
          console.log(response.data.data.page);
          setCurrentPage(response.data.data.page);
          setTotal(response.data.data.total_count);

          setOrders(response.data.data.data);
          setFetching(false);
        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Txns', '', 'error');
      })
  }
  const getOrders = useCallback((filterData = bodyData) => {
    setTrf(filterData);
  }, []);

  useEffect(() => {
    setTrf();
  }, [getOrders]);

  const handlePageChange = (page, limit) => {

    setCurrentLimit(limit);
    var data = bodyData;
    data['page'] = page;
    data['limit'] = limit;
    getOrders(data, data);
    setBodyData(data);
    setSelected({ keys: [], records: [] });
  };


  const handleSelectRecord = (selectedRowKeys, selectedRows) => {
    setSelected({ keys: selectedRowKeys, records: selectedRows });
  };

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      width: '100px',

      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend'],
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '100px',

      sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      sortDirections: ['descend'],
    },
    {
      title: 'picked_at',
      dataIndex: 'picked_at',
      key: 'picked_at',
      width: '100px',

      sorter: (a, b) => parseInt(a.picked_at) - parseInt(b.picked_at),
      sortDirections: ['descend'],
    },
    {
      title: 'picked_qty',
      dataIndex: 'picked_qty',
      key: 'picked_qty',
      width: '100px',
    },

    {
      title: 'picker_account_id',
      dataIndex: 'picker_account_id',
      key: 'picker_account_id',
      width: '100px',
    },
    {
      title: 'picker_lead_id',
      dataIndex: 'picker_lead_id',
      key: 'picker_lead_id',
      width: '100px',
    },
    {
      title: 'sku_code',
      dataIndex: 'sku_code',
      key: 'sku_code',
      width: '100px',
    },
    {
      title: 'planned_qty',
      dataIndex: 'planned_qty',
      key: 'planned_qty',
      width: '100px',
    },
    {
      title: 'truck',
      dataIndex: 'truck',
      key: 'truck',
      width: '100px',
    },
    {
      title: 'verified_qty',
      dataIndex: 'verified_qty',
      key: 'verified_qty',
      width: '100px',
    },
    {
      title: 'zone_id',
      dataIndex: 'zone_id',
      key: 'zone_id',
      width: '100px',
    },


    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      width: '100px',
    },

  ];


  const inputs = [
    {
      name: 'zone_id',
      label: 'zone_id',
      rules:
        [
          {
            required: false,
            message: 'Scheduled End is required'
          }
        ],
      hasFeedback: true,
      col: true,
      defaultValue: "please input",
    },
    {
      name: 'status',
      label: 'status',
      rules:
        [
          {
            required: false,
            message: 'Scheduled End is required'
          }
        ],
      hasFeedback: true,
      col: true,
      defaultValue: "please input",
    },
    {
      name: 'picker_account_id',
      label: 'picker_account_id',
      rules:
        [
          {
            required: false,
            message: 'Scheduled End is required'
          }
        ],
      hasFeedback: true,
      col: true,
      defaultValue: "please input",
    },
    {
      name: 'truck',
      label: 'truck',
      rules:
        [
          {
            required: false,
            message: 'Scheduled End is required'
          }
        ],
      hasFeedback: true,
      col: true,
      defaultValue: "please input",
    },


  ];

  const onFormFinish = async () => {
    const { meta, ...values } = await form.validateFields();
    var addValues = {};
    var hubId = -1, nextHubId = -1, clusterId = -1;
    for (var value in values) {
      if (values[value] !== '' && values[value] !== null && values[value] !== undefined) {
        addValues[value] = values[value];
        if (values[value] instanceof moment) {
          addValues[value] = values[value].format("YYYY-MM-DD");
        }
      }
    }

    console.log(addValues);
    addValues['page'] = currentPage;
    addValues['limit'] = currentLimit;
    setBodyData(addValues);
    getOrders(addValues);
  };

  const onClose = () => {

    setFilterDrawer(!filterDrawer);
  }


  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData();
    formData.append("file", uploadedFile);

    console.log(formData);
    try {
      const response = axios({
        method: "post",
        url: `${API_URI}/admin/v1/ptrf`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (error) {
      console.log(error)
    }
  }

  const handleFileSelect = (event) => {

    setFile(event.target.files[0])
  }

  return (
    <Admin sidenav={props.nav}>
      <Space></Space>
      <div style={{ boxSizing: "border-box;", marginTop: "50px", height: "248px" }}>
        <DragDropPtrf></DragDropPtrf>
      </div>    </Admin >
  );
}

export default PtrfUpload;
