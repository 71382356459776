import React, { useState, useEffect } from 'react';
import { PageHeader,Button,Form,AutoComplete,Spin, message } from 'antd';
import NewTable from '../../../molecules/NewTable/NewTable';
import axios from '../../../../utils/custom-axios';
import { Admin } from '../../../templates2';
import { API_URI } from '../../../../config';
import sendNotification from '../../../../utils/sendNotification';
import { SearchOutlined,EditFilled ,PlusCircleFilled } from '@ant-design/icons';
import { Input } from 'antd';
import AddSku from './AddSku';
import errorHandler from '../../../../utils/errorHandler';

function ManageSku(props) {
  const [selected, setSelected] = useState({ keys: [], records: [] });
  const [isFetching, setFetching] = useState(false);
  const [storeRecord, setStoreRecord] = useState([]);
  const [shipments, setOrders] = useState([]);
  const [propsData,setpropsData] = useState();
  const [getBins,setGetBins] = useState([]);
  // const [getZones,setgetZones] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopUp, setPopUp] = useState(false);
  const [isAddPopUp, setAddPopUp] = useState(false);
  const [currentLimit, setCurrentLimit] = useState(100);
  const [total, setTotal] = useState(0);
  const [ResultValue,setResultValue] = useState([]);
  const [isLoaded,setIsLoaded] = useState(false);
  const [targetValue,settargetValue]= useState();
  const { Option } = AutoComplete;

  const [getmails,setgetmails] = useState([]);
  const [searchText,setSearchText] = useState("text");
  var valuee =[];
  var _result = [];
  const togglePopup = () => {
    setPopUp(!isPopUp);
  }
  const _togglePopup = () => {
    setAddPopUp(!isAddPopUp);
  }

  

  // const getZonesList = () => {
  //   console.log("heyeyy");
  //   let data = '',
  //     apiUrl = `${API_URI}/admin/v1/zones`;
  //   let authorization = localStorage.getItem("jwtToken");
  //   console.log(apiUrl);

  //   axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
  //     .then((response) => {

  //       console.log(response);

  //       if ("data" in response && "data" in response.data) {
  //         //console.log(response.data.data);
  //         setgetmails(response.data.data);
  //         var len  = response.data.data.data;
  //         for(let i=0;i<len.length;i++) {
             
  //               var _store  = len[i].name;
  //               console.log(_store);
  //               const after_ = _store.substring(_store.indexOf('-') + 1);
  //               console.log(after_);
  //               getZones.push(after_);
  //         }
  //         setgetZones(getZones);
  //       }
  //     })
  //     .catch(async (error) => {

  //       await errorHandler(error);
  //       setFetching(false);
  //       sendNotification('Failed to fetch Mail Lists', '', 'error');
  //     });
  // }

  useEffect(() => {
  }, []);


  useEffect(() => {
    // getZonesList();
    
  }, []);

  const handlePageChange = (page, limit) => {
    setCurrentLimit(limit);
    setSelected({ keys: [], records: [] });
  };


  const handleSelectRecord = (selectedRowKeys, selectedRows) => {
    setSelected({ keys: selectedRowKeys, records: selectedRows });
  };


  const columns = [
    {
      title: 'Case Code',
      dataIndex: 'case_code',
      key: 'case_code',
      width: '80px',

    },
    {
      title: 'Sku Code',
      dataIndex: 'sku_code',
      key: 'sku_code',
      width: '100px',
    },
    {
      title: 'Zone',
      dataIndex: 'zone_id',
      key: 'zone_id',
      width: '100px',
    },
    {
      title: 'Bin',
      dataIndex: 'bin_loc',
      key: 'bin_loc',
      width: '100px',
    },
    {
      title: 'Item Name',
      dataIndex: 'name',
      key: 'name',
      width: '100px',
    },
    {

      width: '1px',
      render: (text, record) => {
        return <EditFilled style={{ color: 'var(--primary-color)' }} onClick={() => {
          console.log("hello")
          setpropsData(record);
          setPopUp(true);

        }} />
      }
    },

  ];
 

  const handleSearch = ()=> {
    console.log(searchText);
    var _store;

    console.log("heyeyy");

    if(searchText=="text") {
        message.warning("Please Enter SKU Code or Case Code to Search");
        return ;
    }
    let data = '',
    apiUrl=`${API_URI}/admin/v1/sku?search=${searchText}`;
    let authorization = localStorage.getItem("jwtToken");
    console.log(apiUrl);

    axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);

        if ("data" in response && "data" in response.data) {
          console.log(response.data.data.page);
          setCurrentPage(response.data.data.page);
          setTotal(response.data.data.total_count);
          setStoreRecord(response.data.data.data[0].sku_code);
          setOrders(response.data.data.data);
          setResultValue(response.data.data.data);
          valuee = response.data.data.data;
          _result = response.data.data.data;
          console.log(valuee);
          _store = response.data.data.data[0].sku_code;
          settargetValue(response.data.data.data[0]);
          console.log(_store);
          setIsLoaded(true);
          console.log(response.data.data);
          setFetching(false);
        }
      })
      .catch(async (error) => {

        await errorHandler(error);

       // setFetching(false);
        sendNotification('Failed to Search SKU , Please Check the SKU Code or Case Code Again !', '', 'error');
      })
  }

  return (
    <Admin sidenav={props.nav}>
      <PageHeader
        title=""
        extra={[
        ]}

      > 
      <div style={{ marginTop:10,left: '20.83%', right: '20.83%', paddingRight:'3%'}}>
        <div style={{marginTop:'3%'}}></div>
            <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}>
              <Input  onChange={(event)=>{setSearchText(event.target.value);console.log(event.target.value)}} id = "searchBox" style={{ padding: '8 16', height: 48, border: '2px solid #D9DBE9', borderRadius: 12, width: '80%', gap: 16, background: '#ffffff', }} placeholder="Search by Sku Code or Case Code " prefix={<SearchOutlined />} />
            <Button  onClick={()=> {handleSearch()}} type="primary" style={{ padding:'10px 30px', textAlign:'center',display:'inline-block',height:48, width:'12%',border: '2px solid #D9DBE9',borderRadius: 12, }}> Search</Button>
        </div>
                <div style={{ display:'flex',justifyContent:'space-between',flexWrap:'wrap',width:'100%',backgroundColor:'#ffffff', height:'70px', marginTop:'3%', borderRadius:'16px'}}>
                <p  style={{ color:'var(--primary-color)', fontWeight:600, marginLeft:'5%', padding:'23px 0px'}}>Add New SKU </p>
                <PlusCircleFilled onClick={()=> {
                    setAddPopUp(true);
                }} style={{fontSize: '24px', padding:'20px 0px' ,color:'var(--primary-color)'}} />
                </div>
        </div>
      </PageHeader>
      {isLoaded &&
        <div style={{ paddingLeft: '3%', paddingRight: '3%', marginTop: 34 }}>
          <NewTable
            rowKey="truck"
            rowClassName={"editable-row"}
            columns={columns}
            dataSource={shipments}
            loading={isFetching}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: currentLimit,
              defaultCurrent: 1,
              total: total,
              onChange: handlePageChange,
            }}
            size="small"
            scroll={{ x: '1600px' }}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: selected.keys,
              onChange: handleSelectRecord,
            }}
          />
        </div>}
      {isFetching && <div style={{
        flex: 1, width: "100%",
        height: 500 + "px",
        backgroundColor: "#f0f2f5",
        textAlign: 'center',
        justifyContent: 'center',
        verticalAlign: 'center',
        padding: 150 + "px"
      }}><Spin></Spin></div>}
      {isPopUp &&   
      <>
      <AddSku  bin={getBins}  data={propsData} isModalVisible={isPopUp} handleClose={togglePopup}/>
        
       </>
      }
      {isAddPopUp &&   
      <>
      <AddSku  bin={getBins} isModalVisible={isAddPopUp} handleClose={_togglePopup}/>
        
       </>
      }
    </Admin>
  );
}

export default ManageSku;
