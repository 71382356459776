import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button, Menu } from 'antd';
import { Divider } from 'antd';

import growsariLogo from '../../../assets/side-nav-gs-icon.png';
import userIcon from '../../../assets/user-icon.png';
import { Avatar } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;

const evalPattern = (pattern, params) => {
  const parts = pattern.split('/');
  return parts.map((item) => params[item.slice(1)] || item).join('/');
};

function SideNav({ sections }) {
  const match = useRouteMatch();

  const { path: navKey = '', items: navItems = [] } =
    sections.find((item) => {
      var check = false;
      if (item.submenu) {
        item.submenu.find((subnav) => {
          check = match.path.includes(subnav.path);
          if (check) { item.path = subnav.path; }
          return match.path.includes(subnav.path);
        });
        return check;
      }
      else { return match.path.includes(item.path); }
    }) || {};

  const reverse = [...navItems];
  const { path: subNavKey = '' } =
    reverse.reverse().find((item) => match.path.includes(item.path)) || {};

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('authType');
    localStorage.removeItem('jwtToken');
    window.location.reload();
  };

  return (
    <div style={{width:'100%'}}>
      <div style={{ backgroundColor: "#686868", top: "150px", height: "100px" }}>
        <div style={{ textAlign: "center", paddingTop: "30px", paddingBottom: "30px" }}>
          <img src={growsariLogo} alt="growsari" width={120} />
        </div>
      </div>
      <Menu
        theme="dark"
        selectedKeys={[subNavKey || navKey]}
        defaultOpenKeys={[subNavKey || navKey]}
        mode="inline"
        style={{ fontWeight: "bold", color: "red", backgroundColor: "#686868" }}

      >
        {sections.map(({ items, ...nav }) => {
          if (!items) {
            if (nav.submenu) {
              return (
                <Menu.SubMenu
                  key={nav.path}
                  icon={nav.icon}
                  title={nav.label}
                  disabled={Boolean(nav.disabled)}

                >
                  {nav.submenu.map((subNav) => (
                    <Menu.Item key={subNav.path} disabled={Boolean(subNav.disabled)} icon={subNav.icon}>
                      <Link to={evalPattern(subNav.path, match.params)}>
                        <>
                          {subNav.label}
                        </>
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            }
            return (
              <Menu.Item key={nav.path} disabled={Boolean(nav.disabled)} icon={nav.icon} style={{ color: "white" }}>
                <Link to={evalPattern(nav.path, match.params)}>
                  <>
                    {nav.label}
                  </>
                </Link>
              </Menu.Item>
            );
          }
          return (
            <Menu.SubMenu
              key={nav.path}
              icon={nav.icon}
              title={nav.label}
            >
              {items.map((subNav) => (
                <Menu.Item key={subNav.path} disabled={Boolean(subNav.disabled)} icon={subNav.icon}>
                  <Link to={evalPattern(subNav.path, match.params)}>
                    <>{subNav.label}
                    </>
                  </Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          );
        })}
      </Menu>
      <div style={{ paddingLeft: "50px", paddingRight: "50px" }}>
        <Divider style={{ backgroundColor: "white" }} />
      </div>
      <div style={{ backgroundColor: "#686868", height: "100px" }}>
        <div style={{ textAlign: "center", paddingTop: "30px", paddingBottom: "30px", color: "white" }}>
          <div> <img src={userIcon} alt="growsari" width={30} />
          </div>
          <p style={{fontWeight:600}}> {localStorage.getItem("displayName")}</p>
          <p style={{marginTop:'-5%',fontSize:12}}>
            {localStorage.getItem("emailId")}
            </p>
            <Button onClick={handleLogout} type="primary"  style={{ alignItems: "center", width: "100%" }} icon={<LogoutOutlined />}>
              Logout
            </Button>

          </div></div>
      </div>
    
  );
}

export default SideNav;
