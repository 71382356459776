import React, { useState, useEffect, useCallback } from 'react';
import userIcon from '../../../../assets/ptrf-upload.png';
import checkIcon from '../../../../assets/check.png';
import fileIcon from '../../../../assets/file-icon.png';
import { Modal, Button } from 'antd';

import { Divider } from 'antd';

import { Upload, message, Space, Progress, Select } from 'antd';

import { API_URI } from '../../../../config';
import { EditableTable, SimpleForm } from '../../../molecules';
import sendNotification from '../../../../utils/sendNotification';

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

  
//   export default ModalComponent;

function DragDrop() {
    const [progress, setProgress] = useState(0);
    // const [trucks, setTrucks] = useState([{ "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }]);
    const [trucks, setTrucks] = useState([]);
    const [visible, setVisible] = useState(false);
    const [fileName, setFileName] = useState("");
    const [isFetching, setFetching] = useState(false);
    const [poType, setPoType] = useState("MFC PO TYPE");
    let authorizationToken = localStorage.getItem("jwtToken");
    const [data,setData]  =useState([])
    const { Dragger } = Upload;
   
    const props = {
        name: 'file',
        action: `${API_URI}/uploader/v1/ptrf_dispatched`,
        // data: { "po_type": poType },
        headers: {
            authorization: authorizationToken,
        },
        onChange: async (info) => {
            ;
            const { status } = info.file;
            ;
            await setProgress(20);
            await sleep(400);

            // await setProgress(40);
            ;

            if (status !== 'uploading') {
                if (info.file.type != "text/csv") {

                    sendNotification("File format is invalid", "Unable to read the file. Please make sure it follows the proper format.", "exit_error")
                    await setProgress(0);

                    return
                }
                ;

                await setProgress(50);
                setFetching(true);
            }
            
            if (status === 'done') {
                for (var i = 50; i <= 100; i = i + 10) {
                    await setProgress(i);

                    await sleep(100);
                }
                setFileName(info.file.name);
                message.success(`${info.file.name} file uploaded successfully.`);
                console.log(info.file.response)
                postSuccess(info.file.response);
            } else if (status === 'error') {
                console.log(info);
                console.log(info['file']['response']['message'])

                await setProgress(0);
                message.error(`${info.file.response.message}`);
                sendNotification(info.file.response.message, info.file.response.error_message, "error", 10)
                setTimeout(() => {
                    // setMessage('Timeout after 1 second');
                  }, 5000);
                await setData(info.file.response.additional_info[0].duplicate_data)
                console.log(data)
                // setVisible(true);
            }
        },
        onDrop(e) {

            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    useEffect(() => {
        console.log("Data updated:", data);
        if (data.length > 0) {
            const timeout = setTimeout(() => {
                setVisible(true);
            }, 1000);
    
            return () => clearTimeout(timeout);
        }
    }, [data]);

    function postSuccess(res) {
        console.log(res);
        var dataSource = res['data'];
        setTrucks(dataSource);
        setFetching(false);
    }

    var textStyle = {

        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "15px",
        lineHeight: "24px",
        color: "#4E4B66",

    }

    const columns = [

        {
            title: 'truck',
            dataIndex: 'truck',
            key: 'truck',
            width: '50px',
            render: text => <p style={textStyle}>{text}</p >,

        },
        {
            title: 'dst_warehouse',
            dataIndex: 'dst_warehouse',
            key: 'dst_warehouse',
            width: '100px',
            render: text => <p style={textStyle}>{text}</p >,

        },
        {
            title: 'loading_date',
            dataIndex: 'loading_date',
            key: 'loading_date',
            width: '50px',
            render: text => <p style={textStyle}>{text}</p >,

        },
        {
            title: 'staging_area',
            dataIndex: 'staging_area',
            key: 'staging_area',
            width: '50px',
            render: text => { return (<p style={textStyle}>{text}</p >) },

        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
            width: '50px',
            render: text => <p style={textStyle}>{text}</p >,

        },
        {
            title: '',
            dataIndex: 'status',
            key: 'status',
            width: '50px',
            render: tags => (
                <img width="26px" src={checkIcon} ></img >
            ),
        }

    ];


    const inputs = [{
        'id': "MFC PO TYPE",
        "value": "MFC PO TYPE",
        "label": "MFC PO TYPE"
    }, {
        'id': "CH PO TYPE",
        "value": "CH PO TYPE",
        "label": "CH PO TYPE"
    }, {
        'id': "OTHER PO TYPE",
        "value": "OTHER PO TYPE",
        "label": "OTHER PO TYPE"
    }];
    const onChange = (event) => {
        console.log(event)
        setPoType(event);
    }
    const handleOk = () => {
        setVisible(false);
      };
    
      const handleCancel = () => {
        setVisible(false);
      };
    return (
        <>
            <div style={{ padding: "30px", paddingRight: "130px", paddingLeft: "130px" }}>
                <p style={{ fontWeight: "bold", fontSize: "24px" }}>Hi, {localStorage.getItem("displayName")}</p>
                <p >Upload PTRF file 1 file at a time and make sure it is in the <a href="https://supply-dev.s3.ap-southeast-1.amazonaws.com/PTRF+-+Dispatch+-+Sheet1+(1).csv">proper format</a> </p>
                {/* <p> Please select the PO type</p>
                <Select style={{ width: 220 }} defaultValue="MFC PO TYPE"
                    onChange={onChange} options={inputs}>
                </Select> */}
                <br></br>
                <br></br>
                <Dragger {...props}>
                    <p className="ant-upload-text" style={{ color: "#6E7191;!important" }}>Drag and Drop file here</p>
                    <Space></Space>
                    <p> or </p>
                    <Space></Space>
                    <br></br>
                    <img src={userIcon} width={200}></img>
                </Dragger>
                <Progress percent={progress}></Progress>
                <Divider />
            </div>

            {(trucks.length) > 0 && <div style={{ padding: "10px", paddingRight: "130px", paddingLeft: "130px" }}>
                {fileName && <div> <img src={fileIcon} /> {fileName}</div>}

                Orders generated from PTRF
                <div></div>
                <EditableTable
                    style={{ paddingTop: "20px" }}
                    rowKey="truck"
                    rowClassName={() => 'editable-row'}
                    columns={columns}
                    dataSource={trucks}
                    loading={isFetching}
                    scroll={{ y: '200px' }}

                    size="small"

                /></div>
            }
            <Modal
  title="Data that are already present in the Database"
  visible={visible}
  onOk={handleOk}
  onCancel={handleCancel}
  footer={[
    <Button key="ok" type="primary" onClick={handleOk}>
      OK
    </Button>
  ]}
  bodyStyle={{
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Adding shadow
    // padding: '20px',
    backgroundColor: '#FFFFFF' // Background color
  }}
>
  <ul style={{ listStyleType: 'none', padding: 0 }}>
    {data.map((item, index) => (
      <li key={index} style={{ marginBottom: '10px' }}>
        <div style={{ backgroundColor: '#07A197', color: '#FFFFFF', padding: '10px', borderRadius: '5px', textAlign: 'center' }}>
          <span style={{ fontWeight: 'bold' }}>Truck:</span> {item.truck}, <span style={{ fontWeight: 'bold' }}>PO Type:</span> {item.po_type}
        </div>
      </li>
    ))}
  </ul>
</Modal>
        </>
    );
}

export default DragDrop;