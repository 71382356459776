/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Table,
  Input,
  InputNumber,
  Switch,
  Form,
  Spin,
  notification,
} from 'antd';
import { useMutation } from '@apollo/react-hooks';

const EditableContext = React.createContext();

const EditableInput = React.forwardRef(
  (
    {
      onToggle,
      form,
      title,
      dataIndex,
      record,
      editableProps: { type, value, min = 0, max, saveData, config = {} },
    },
    ref,
  ) => {
    const [loading, setLoading] = useState(false);

    const save = async () => {
      try {
        const values = await form.validateFields();
        const val = value || record[dataIndex];
        
        if (val !== values[dataIndex]) {
          record[dataIndex] = values[dataIndex];
          saveData(record);
          saveData(values);
          return;
        }
        onToggle();
      } catch ({ errorFields = [] }) {
        onToggle();
        errorFields.map(({ name = [], errors = [] }) =>
          notification.error({
            message: `Validation Error on "${name[0]}"`,
            description: JSON.stringify(errors, null, 2),
          }),
        );
      }
    };

    switch (type) {
      case 'switch':
        return (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            valuePropName="checked"
          >
            <Switch ref={ref} onChange={save} loading={loading} />
          </Form.Item>
        );

      case 'number':
        return loading ? (
          <Spin />
        ) : (
            <Form.Item
              style={{
                margin: 0,
              }}
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ]}
              validateTrigger="onBlur"
            >
              <InputNumber
                max={max}
                min={min}
                ref={ref}
                onPressEnter={save}
                onBlur={save}
              />
            </Form.Item>
          );

      default:
        return loading ? (
          <Spin />
        ) : (
            <Form.Item
              style={{
                margin: 0,
              }}
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ]}
              validateTrigger="onBlur"
            >
              <Input ref={ref} onPressEnter={save} onBlur={save} width={200} />
            </Form.Item>
          );
    }
  },
);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  editableProps = {},
  children,
  dataIndex,
  record = {},
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  useEffect(() => {
    form.setFieldsValue({
      [dataIndex]: editableProps.value || record[dataIndex],
    });
  }, [dataIndex, editableProps.value, form, record]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  let childNode = children;

  if (editable) {
    childNode =
      editableProps.isActive || editing ? (
        <EditableInput
          ref={inputRef}
          form={form}
          title={title}
          record={record}
          dataIndex={dataIndex}
          editableProps={editableProps}
          onToggle={toggleEdit}
        />
      ) : (
          <div
            className="editable-cell-value-wrap"
            style={{
              paddingRight: 24,
            }}
            onClick={toggleEdit}
          >
            {children}
            {'\u00A0'}
          </div>
        );
  }

  return <td {...restProps}>{childNode}</td>;
};

function NewTable({ columns, ...props }) {
  return (
    <Table style={{backgroundColor:'#fffff' , padding:0 }}
      components={{
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      }}
      columns={columns.map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          onCell: (record = {}) => ({
            record,
            editable: col.editable,
            editableProps: col.editableProps(record),
            dataIndex: col.dataIndex,
            title: col.title,
          }),
        };
      })}
      {...props}
    />
  );
}

export default NewTable;
