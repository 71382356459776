import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { SideNav } from '../../molecules';
import services from '../../../services';

import warehouseImg from '../../../assets/side-nav-gs-icon.png';
const { Header, Sider, Content, Footer } = Layout;

const getServiceInfo = (serviceCode) => {
  return services.find((service) => service.code === serviceCode);
};

function Admin({ sidenav = 'iam', children }) {
  const service = getServiceInfo(sidenav);
  const [width, setWidth] = useState(window.innerWidth);
  const [collapsed, onNavCollapse] = useState(width <= 500 ? true : false);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth <= 500) { onNavCollapse(true); }
    else { onNavCollapse(false); }
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [])

  return (
    <Layout id="admin-template" >

      <Layout>
        <Sider  style={{ backgroundColor: "#686868", flexGrow: 0, flexShrink: 0, flexBasis: "300px", maxWidth: "300px", width: "256px" }}>
          <SideNav sections={service.nav} />
        </Sider>
        <Layout>
          <Content className="admin-content">{children}</Content>
          <Footer className="app-footer">Pick Load © GrowSari 2022</Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Admin;
