import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Divider, Spin, Modal } from 'antd';
import NewTable from '../../../molecules/NewTable/NewTable';
import axios from '../../../../utils/custom-axios';
import { API_URI } from '../../../../config';
import { DatePicker } from 'antd';
import errorHandler from '../../../../utils/errorHandler';

const Popup = props => {
  const [selected, setSelected] = useState({ keys: [], records: [] });
  const [isFetching, setFetching] = useState(false);
  const [trfRecords, setTrfRecords] = useState([]);
  const [setValue, setStateValue] = useState([]);
  const [form] = Form.useForm();
  const [_date, setDate] = useState("Date Here");
  const [isModalVisible, setisModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(100);
  const [total, setTotal] = useState(0);
  var date = new Date();
  const [bodyData, setBodyData] = useState({ 'page': 1, 'limit': 100 });
  const { RangePicker } = DatePicker;
  var data;
  const togglepopUp = props.handleClose;
  useEffect(() => {
    setStateValue(props.data);
    console.log(props);
    debugger;
    var _store = props.data.created_at;
    _store = _store.split(" ");
    var dateString = [];
    dateString.push(_store[1]);
    dateString.push(_store[2]);
    dateString.push(_store[3]);
    var _datestring = dateString[0] + " " + dateString[1] + " " + dateString[2];
    console.log(_datestring);
    setDate(_datestring);
    var _id = props.data;
    let data = '',
      apiUrl = `${API_URI}/admin/v1/trf?page=${currentPage}&limit=${currentLimit}&truck=${props.data.truck}`;

    setFetching(true);
    let authorization = localStorage.getItem("jwtToken");
    console.log(apiUrl);

    axios.get(apiUrl, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);

        if ("data" in response && "data" in response.data) {
          console.log(response.data.data.page);
          setCurrentPage(response.data.data.page);
          setTotal(response.data.data.total_count);
          //;
          setTrfRecords(response.data.data.data);
          data = response.data.data.data;
          setFetching(false);
          setisModalVisible(true);
          console.log(data);
          debugger;
        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        // sendNotification('Failed to fetch Txns', '', 'error');
      })
  }, []);

  const columns = [
    {
      title: 'Item Name',
      dataIndex: 'sku_name',
      key: 'sku_name',
      width: '80px',
      align: 'left'
    },
    {
      title: 'Case Code',
      dataIndex: 'sku_case_code',
      key: 'sku_case_code',
      width: '100px',
    },
    {
      title: 'Sku Code',
      dataIndex: 'sku_code',
      key: 'sku_code',
      width: '100px',

    },

    {
      title: 'Quantity',
      dataIndex: 'planned_qty',
      key: 'planned_qty',
      width: '100px',
    },
    {
      title: 'Zone',
      dataIndex: 'zone_id',
      key: 'zone_id',
      width: '100px',
    },
    {
      title: 'Bin',
      dataIndex: 'sku_bin_loc',
      key: 'sku_bin_loc',
      width: '100px',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '100px',
    },
    {
      title: 'Cases Picked',
      dataIndex: 'picked_qty',
      key: 'picked_qty',
      width: '100px',
    },



  ];
  const handleSelectRecord = (selectedRowKeys, selectedRows) => {
    setSelected({ keys: selectedRowKeys, records: selectedRows });
  };
  const handlePageChange = (page, limit) => {

    setCurrentLimit(limit);
    var data = bodyData;
    data['page'] = page;
    data['limit'] = limit;
    //getOrders(data, data);
    setBodyData(data);
    setSelected({ keys: [], records: [] });
  };

  return (
    <>
      <Modal okButtonProps={{ style: { display: 'none' } }} visible={isModalVisible} onCancel={togglepopUp} width={1000} style={{ borderRadius: "20px !important", top: 20 }} >

        {/* <div className="popup-box">

          <div className="box"> */}
        <div style={{ borderRadius: "20px" }}>
          <Row gutter={16}>
            <Col span={12}>
              <div style={{ marginLeft: 30 }}>
                <p style={{ fontWeight: 700, size: 24 }}>{setValue.dst_warehouse} PICK LIST</p>

                <p style={{ color: '#07A197' }}>{setValue.status}</p>
                <p>Total Cases Picked : {props.data.picked_qty}</p>
                <p>picking Duration : {props.data.picking_duration}</p>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ marginLeft: 220 }}>
                <p style={{ fontSize: 12, }}>Order Id : {setValue.truck} </p>
                <p style={{ fontSize: 12, }}>PTRF Id : {setValue.id} </p>
                <p style={{ fontSize: 12, }}>Staging Area : {setValue.staging_area} </p>
                <p style={{ fontSize: 12, }}>Date Uploaded : {_date} </p>

              </div>
            </Col>
            <Divider />
          </Row>

          {!isFetching &&
            <div style={{ paddingLeft: '3%', paddingRight: '3%', marginTop: 34 }}>

              <NewTable
                rowKey="truck"
                rowClassName={() => 'editable-row'}
                columns={columns}
                dataSource={trfRecords}
                loading={isFetching}
                pagination={{
                  showSizeChanger: true,
                  defaultPageSize: currentLimit,
                  defaultCurrent: 1,
                  total: total,
                  onChange: handlePageChange,
                }}
                size="small"
                scroll={{ x: '1600px' }}
                rowSelection={{
                  type: 'radio',
                  selectedRowKeys: selected.keys,
                  onChange: handleSelectRecord,
                }}
              />

            </div>}
          {isFetching && <div style={{
            flex: 1, width: "100%",
            height: 500 + "px",
            backgroundColor: "#f0f2f5",
            textAlign: 'center',
            justifyContent: 'center',
            verticalAlign: 'center',
            padding: 150 + "px"
          }}><Spin></Spin></div>}

          {/* </div>
        </div> */}
        </div>
      </Modal>

    </>
  );
};

export default Popup;