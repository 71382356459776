import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Table, Button } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: monitor => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: item => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: { index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));

    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{ cursor: 'move', ...style }}
            {...restProps}
        />
    );
};


// function DragSortTable({ onComplete, columns2, dataSource, rankParam = "rank", allowUpdate = false, reqRemovedTrucks = [] }) {
function DragSortTable({ onComplete, columns2, dataSource, reqRemovedTrucks = [], rankParam = "rank", allowUpdate = false, }) {
    console.log("reqRemovedTrucks");
    var testTruck = reqRemovedTrucks;
    console.log(reqRemovedTrucks);
    const columns = columns2;
    const [data, setData] = useState(dataSource);
    var allowUpdate = (reqRemovedTrucks && reqRemovedTrucks.length > 0 ? true : allowUpdate);
    const [saveButton, setsaveButton] = useState(allowUpdate);
    const [removedTrucks, setRemovedTrucks] = useState(reqRemovedTrucks)
    var diffArray = [];

    useEffect(() => {
        console.log("datasource....");
        //debugger;
        setData(dataSource);
    }, [dataSource]);
    useEffect(() => {

        console.log("reqRemovedTrucks....");
        //debugger;
        setRemovedTrucks(reqRemovedTrucks);
    }, [testTruck]);
    // }, []);
    useEffect(() => {

        console.log("allowUpdate....");
        //debugger;
        setsaveButton(allowUpdate);
    }, [allowUpdate]);

    var clone = JSON.parse(JSON.stringify(dataSource));

    clone.sort(function (a, b) {
        var keyA = new Date(a[rankParam]),
            keyB = new Date(b[rankParam]);
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
    });
    for (var i = 0; i < clone.length; i++) {
        diffArray[i] = clone[i][rankParam];
    }
    const [defaultRank, setDefaultRank] = useState(diffArray);
    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = data[dragIndex];
            console.log(dragRow);
            console.log(dragIndex);
            console.log(data);

            setData(
                update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );
            setsaveButton(true);
            console.log(data);
        },
        [data],
    );
    ;
    function saveQueue() {
        //debugger;
        onComplete(data, defaultRank);
    }

    function cancelQueue() {
        if (reqRemovedTrucks.length > 0) {
            window.location.reload();
        }
        //debugger;
        setData(dataSource);
        setsaveButton(false);
    }
    ;
    return (
        <div style={{ height: "10px" }} >

            <DndProvider backend={HTML5Backend} style={{height:"100px"}}>
                <Table
                    columns={columns}
                    dataSource={data}
                    components={components}
                    onRow={(record, index) => ({
                        index,
                        moveRow,
                    })}
                    pagination={{
                        position: [ "bottom"],
                        showSizeChanger: true,
                        defaultPageSize: 100,  
                    }}
                    scroll={{
                        y: 400,
                      }}
                />
                {removedTrucks != undefined && removedTrucks.length > 0 && <> <div>Trucks removed from queue</div><Table
                    columns={columns}
                    dataSource={removedTrucks}
                    components={components}

                /></>}
            </DndProvider>
            <div style={{ display:'flex',justifyContent:'space-between', marginLeft:'75%', marginTop: "5px",}}>

                <Button ghost style={{
                    // alignItems: "center",
                    // padding: "9px 22px",
                    // gap: "12px",
                    // width: "auto",
                    // height: "40px",
                    // border: "1px solid #06B6AA",
                    // borderRadius: "8px",
                    // color: "#06B6AA",
                    // marginRight:"2px",
                    color: 'var(--primary-color)',  borderColor:'var(--primary-color)',
                    padding:'5px 20px',width:90, height:'35px', marginTop:'2%' , 
                    
                }} onClick={cancelQueue}>cancel </Button> 

                <Button type="primary" style={{
                     padding:'5px 24px' , height:'35px', marginTop:'2%' ,
                     alignItems:'center'
                   
                }}  onClick={saveQueue}>Update Queue </Button>
{/* disabled={!saveButton} */}

            </div>
        </div>
    );
};

export default DragSortTable;
