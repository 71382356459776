
import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter, Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import apolloClient from './apolloClient';
import { AdminRoute, GuestRoute, MerchantRoute, InventoryRoute, SuperVisorRoute, PrivateRoute, TransferUploaderRoute } from './lib/routes';
import {
  Login,
  TruckList,
  LoadingList,
  PtrfUpload,
  NotFound,
  PtrfUploadV2
} from './components/pages';
import ManageOrders from './components/pages/Admin/ManageOrders/ManageOrders';
import ManageSku from './components/pages/Admin/ManageSku/ManageSku';
import moment from 'moment';
import 'jsonminify';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import TruckLoadList from './components/pages/Admin/TruckLoadList/TruckLoadList';

function App() {
  return (


    <ApolloProvider client={apolloClient}>
      <HashRouter>
        <LastLocationProvider>
          <Switch>

            <GuestRoute exact path="/login/" component={Login} />
            {/* Admin Routes */}
            <AdminRoute exact path="/admin/" component={TruckList} nav="admin-trucks" />
            <AdminRoute exact path="/admin/trucks/" component={TruckList} nav="admin-trucks" />

            <AdminRoute exact path="/admin/trf" component={PtrfUpload} nav="admin-trf" />
            <AdminRoute exact path="/admin/pickList/" component={ManageOrders} nav="admin-picklist" />

            <SuperVisorRoute exact path="/supervisor/pickList/" component={ManageOrders} nav="supervisor-picklist" />

            <SuperVisorRoute exact path="/supervisor/trucks/" component={TruckList} nav="supervisor-trucks" />

            <SuperVisorRoute exact path="/supervisor/loading/" component={LoadingList} nav="supervisor-loading" />
            <InventoryRoute exact path="/inventory/trf/" component={PtrfUpload} nav="inv-trf" />

            <AdminRoute exact path="/admin/sku/" component={ManageSku} nav="sku" />
            <TransferUploaderRoute exact path="/transfer_uploader/trf/" component={PtrfUploadV2} nav="transfer-uploader-trf" />


            <AdminRoute exact path="/admin/loading/" component={LoadingList} nav="admin-loading"></AdminRoute>
            <Redirect exact path="/" to="/login" />
            <Route component={NotFound} />
          </Switch>
        </LastLocationProvider>
      </HashRouter>
    </ApolloProvider >
  );
}

export default App;
