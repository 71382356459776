import React, { useState } from 'react';
import { Col, Row, Button, Dropdown, Form, Menu } from 'antd';
import axios from '../../../../utils/custom-axios';
import { API_URI } from '../../../../config';
import sendNotification from '../../../../utils/sendNotification';
import { DatePicker } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import errorHandler from '../../../../utils/errorHandler';



export function MarkDispatched(props) {
    const [isFetching, setFetching] = useState(false);
    const [shipments, setOrders] = useState([]);
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const updateStatus = async () => {
        let data = '',
            apiUrl = `${API_URI}/admin/v1/truck_mark_dispatched`;
        setFetching(true);

        let authorization = localStorage.getItem("jwtToken");
        console.log(apiUrl);
        console.log(selectedValue);
        let _store = {
            'truck': props.data.truck
        };
        await axios.post(apiUrl, _store, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
            .then((response) => {
                console.log(response);
                if ("data" in response && "data" in response.data) {
                    sendNotification('Status Updated', '', 'success');
                    setTimeout(function () {
                        window.location.reload(1);
                    }, 1000);
                }
            })
            .catch(async (error) => {

                await errorHandler(error);
                setFetching(false);
                sendNotification('Failed to fetch Txns', '', 'error');
            })

    }
    const [selectedValue, setSelectedValue] = useState(props.data.status);

    function handlesubmitmenu(e) {

        setSelectedValue(e.key);
        console.log(selectedValue);
        var _store = (e.key);
        console.log(_store)

    }
    const togglePopup = props.handleClose;

    return (
        <div className="status-popup-box">

            <div className="status-box">
                <Row gutter={16}>
                    <Col span={24}>
                        <div>
                            <h4>Are you sure this order {props.data.truck} is dispatched? </h4>
                            <span>Once you mark this order as dispatched,you cannot make any further changes</span>
                        </div>
                    </Col>
                </Row>
                <div style={{ marginTop: 30, margin: '0 auto 0 auto' }}>
                    {/* <Button  ghost style={{ width:'45%' , color:'#07A197',borderColor:'#07A197', display:'inline-block'}} onClick={togglePopup}>No</Button>
                    {console.log(props)}
                <Button type="primary" style={{ width:'45%', marginLeft:4}} onClick={updateStatus}> Yes</Button> */}
                    <Button ghost style={{
                        color: 'var(--primary-color)', borderColor: 'var(--primary-color)',
                        marginTop: '2%',
                    }} onClick={togglePopup}>No</Button>
                    {console.log(props)}
                    <Button type="primary" style={{ marginLeft: '3%', marginTop: '2%' }} onClick={updateStatus}> Yes</Button>

                </div>
            </div>
        </div>

    );
}
