import React, { useState, useEffect, useCallback } from 'react';
import userIcon from '../../../../assets/ptrf-upload.png';
import checkIcon from '../../../../assets/check.png';
import fileIcon from '../../../../assets/file-icon.png';


import { Divider } from 'antd';

import { Upload, message, Space, Progress } from 'antd';

import { API_URI } from '../../../../config';
import { EditableTable, SimpleForm } from '../../../molecules';
import sendNotification from '../../../../utils/sendNotification';

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}
function DragDrop() {
    const [progress, setProgress] = useState(0);
    // const [trucks, setTrucks] = useState([{ "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }, { "truck": "NHYG" }]);
    const [trucks, setTrucks] = useState([]);

    const [fileName, setFileName] = useState("");
    const [isFetching, setFetching] = useState(false);

    let authorizationToken = localStorage.getItem("jwtToken");
    const { Dragger } = Upload;
    const props = {
        name: 'file',
        action: `${API_URI}/admin/v1/ptrf`,
        headers: {
            authorization: authorizationToken,
        },
        onChange: async (info) => {
            ;
            const { status } = info.file;
            ;
            await setProgress(20);
            await sleep(400);

            // await setProgress(40);
            ;

            if (status !== 'uploading') {
                if (info.file.type != "text/csv") {

                    sendNotification("File format is invalid", "Unable to read the file. Please make sure it follows the proper format.", "exit_error")
                    await setProgress(0);

                    return
                }
                ;

                await setProgress(50);
                setFetching(true);
            }
            if (status === 'done') {
                for (var i = 50; i <= 100; i = i + 10) {
                    await setProgress(i);

                    await sleep(100);
                }
                setFileName(info.file.name);
                message.success(`${info.file.name} file uploaded successfully.`);

                postSuccess(info.file.response);
            } else if (status === 'error') {
                console.log(info);
                console.log(info['file']['response']['message'])
                await setProgress(0);
                message.error(`${info.file.response.message}`);
                sendNotification(info.file.response.message, info.file.response.error_message, "error", 10)
            }
        },
        onDrop(e) {

            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    function postSuccess(res) {
        console.log(res);
        var dataSource = res['data'];
        setTrucks(dataSource);
        setFetching(false);
    }

    var textStyle = {

        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "15px",
        lineHeight: "24px",
        color: "#4E4B66",

    }

    const columns = [

        {
            title: 'truck',
            dataIndex: 'truck',
            key: 'truck',
            width: '50px',
            render: text => <p style={textStyle}>{text}</p >,

        },
        {
            title: 'dst_warehouse',
            dataIndex: 'dst_warehouse',
            key: 'dst_warehouse',
            width: '100px',
            render: text => <p style={textStyle}>{text}</p >,

        },
        {
            title: 'loading_date',
            dataIndex: 'loading_date',
            key: 'loading_date',
            width: '50px',
            render: text => <p style={textStyle}>{text}</p >,

        },
        {
            title: 'staging_area',
            dataIndex: 'staging_area',
            key: 'staging_area',
            width: '50px',
            render: text => { return (<p style={textStyle}>{text}</p >) },

        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
            width: '50px',
            render: text => <p style={textStyle}>{text}</p >,

        },
        {
            title: '',
            dataIndex: 'status',
            key: 'status',
            width: '50px',
            render: tags => (
                <img width="26px" src={checkIcon} ></img >
            ),
        }

    ];

    return (
        <>
            <div style={{ padding: "30px", paddingRight: "130px", paddingLeft: "130px" }}>
                <p style={{ fontWeight: "bold", fontSize: "24px" }}>Hi, {localStorage.getItem("displayName")}</p>
                <p >Upload PTRF file 1 file at a time and make sure it is in the <a href="https://supply-dev.s3.ap-southeast-1.amazonaws.com/pick-load/sample+ptrf+-+Sheet1.csv">proper format</a> </p>
                <br></br><br></br>
                <Dragger {...props}>
                    <p className="ant-upload-text" style={{ color: "#6E7191;!important" }}>Drag and Drop file here</p>
                    <Space></Space>
                    <p> or </p>
                    <Space></Space>
                    <br></br>
                    <img src={userIcon} width={200}></img>
                </Dragger>
                <Progress percent={progress}></Progress>
                <Divider />
            </div>

            {(trucks.length) > 0 && <div style={{ padding: "10px", paddingRight: "130px", paddingLeft: "130px" }}>
                {fileName && <div> <img src={fileIcon} /> {fileName}</div>}

                Orders generated from PTRF
                <div></div>
                <EditableTable
                    style={{ paddingTop: "20px" }}
                    rowKey="truck"
                    rowClassName={() => 'editable-row'}
                    columns={columns}
                    dataSource={trucks}
                    loading={isFetching}
                    scroll={{ y: '200px' }}

                    size="small"

                /></div>
            }
        </>
    );
}

export default DragDrop;