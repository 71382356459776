import React, { useState, useEffect, useCallback } from 'react';
import { PageHeader, Col, Row, Button, Form, Divider, Spin } from 'antd';

import moment from 'moment';
import axios from '../../../../utils/custom-axios';
import { Admin } from '../../../templates2';
import { EditableTable, SimpleForm } from '../../../molecules';
import { API_URI } from '../../../../config';
import sendNotification from '../../../../utils/sendNotification';
import QueueList from './QueueList';
import errorHandler from '../../../../utils/errorHandler';
import { DatePicker, Radio, Space } from 'antd';
const { RangePicker } = DatePicker;


function TruckList(props) {
  console.log(props);

  const [selected, setSelected] = useState({ keys: [], records: [] });
  const [isFetching, setFetching] = useState(false);
  const [shipments, setOrders] = useState([]);
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(100);
  const [total, setTotal] = useState(0);
  const [dateString, setDateString] = useState("");
  const dateFormat = 'YYYY-MM-DD';
  const [currentDate, setcurrentDate] = useState(moment().add(1, "days").format(dateFormat));
  const [previousDate, setpreviousDate] = useState(moment().subtract(2, "days").format(dateFormat));


  const [bodyData, setBodyData] = useState({ 'page': 1, 'limit': 100, "start_date": previousDate, "end_date": currentDate });

  const DateText = (startDate, endDate) => {
    var temp1 = startDate;
    var temp2 = endDate;
    ;
    console.log(temp1, temp2);
    temp1 = temp1.toString();
    temp2 = temp2.toString();
    var values1 = temp1.split(" ");
    var values2 = temp2.split(" ");
    var month1 = values1[1];
    var month2 = values2[1];
    var date1 = values1[2];
    var date2 = values2[2];
    var year1 = values1[3];
    var year2 = values2[3];
    console.log(month1, month2, date1, date2, year1, year2);
    if (month1 == month2) {
      var datestr = month1 + " " + date1 + "-" + date2 + "," + year1;
      console.log(datestr);
      return datestr;
    }
    else if (month1 != month2 && year1 != year2) {
      var datestr = month1 + " " + date1 + " " + year1 + "-" + month2 + " " + date2 + "" + year2;
      console.log(datestr);
      return datestr;
    }
    else if (month1 != month2) {
      var datestr = month1 + " " + date1 + "-" + month2 + " " + date2 + "," + year1;
      console.log(datestr);
      return datestr;
    }
  }

  const setOnHoldTrucks = (filterData = bodyData) => {
    var _temp = filterData;
    console.log(filterData);
    ;
    var startDate = filterData.start_date;
    var endDate = filterData.end_date;
    var getDate = DateText(moment(startDate), moment(endDate));
    console.log(getDate);
    setDateString(getDate);
    console.log(dateString);
    setcurrentDate(filterData.end_date);
    setpreviousDate(filterData.start_date);
    filterData['start_date'] = filterData.start_date;
    filterData['end_date'] = filterData.end_date;
    console.log(filterData);
    ;
    let data = '',
      apiUrl = `${API_URI}/admin/v1/trucks`;
    filterData['status'] = "ON_HOLD";
    setFetching(true);
    console.log(filterData);
    let authorization = localStorage.getItem("jwtToken");

    //  setBodyData(filterData);
    console.log(apiUrl);

    axios.get(apiUrl, { params: filterData, headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);

        if ("data" in response && "data" in response.data) {
          console.log(response.data.data.page);
          setCurrentPage(response.data.data.page);
          setTotal(response.data.data.total_count);
          setBodyData(_temp);
          setOrders(response.data.data.data);

          setFetching(false);
        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Txns', '', 'error');
      })
  }




  const getOrders = useCallback((filterData = bodyData) => {
    setOnHoldTrucks(filterData);
  }, []);

  useEffect(() => {
  }, [getOrders]);


  useEffect(() => {
    setOnHoldTrucks();
  }, [getOrders]);

  const handlePageChange = (page, limit) => {

    setCurrentLimit(limit);
    var data = bodyData;
    console.log(bodyData);
    data['page'] = page;
    data['limit'] = limit;
    data['start_date'] = previousDate;
    data['end_date'] = currentDate;
    setBodyData(data);
    getOrders(data, data);
    //setBodyData(data);
    setSelected({ keys: [], records: [] });
  };


  const handleSelectRecord = (selectedRowKeys, selectedRows) => {
    setSelected({ keys: selectedRowKeys, records: selectedRows });
  };


  const columns = [

    {
      title: 'Order ID',
      dataIndex: 'truck',
      key: 'truck',
      width: '100px',
    },
    {
      title: 'Warehouse',
      dataIndex: 'dst_warehouse',
      key: 'dst_warehouse',
      width: '100px',
    }, {
      title: 'PTRF ID',
      dataIndex: 'id',
      key: 'id',
      width: '100px',

      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend'],
    },
    {
      title: 'Loading Date',
      dataIndex: 'loading_date',
      key: 'loading_date',
      width: '100px',

    },

    // {
    //   title: 'start_time',
    //   dataIndex: 'start_time',
    //   key: 'start_time',
    //   width: '100px',
    // }, {
    //   title: 'status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: '100px',
    // },
    {
      title: 'Action',
      key: 'operation',
      // fixed: 'right',
      width: '100px',
      render: (text, record) => {
        return <Button type="primary" onClick={() => generateTrf(record)}>Add to Queue</Button>
      },
    },

  ];


  const inputs = [
    {
      name: 'date',
      label: 'Date',
      type: 'rangepicker',
      rules:
        [
          {
            required: false,
            message: 'Scheduled End is required'
          }
        ],
      hasFeedback: true,
      col: true,
      defaultValue: [moment(previousDate, dateFormat), moment(currentDate, dateFormat)],

    },

  ];

  const onFormFinish = async () => {
    ;
    const { meta, ...values } = await form.validateFields();
    var addValues = {};
    var start_date;
    var end_date;
    var hubId = -1, nextHubId = -1, clusterId = -1;
    for (var value in values) {

      if (values[value] !== '' && values[value] !== null && values[value] !== undefined) {
        if (typeof values[value] == "object") {
          ;
          addValues["start_date"] = values[value][0].format(dateFormat);
          addValues["end_date"] = values[value][1].format(dateFormat);
          start_date = values[value][0];
          end_date = values[value][1];
        }
        else {
          addValues[value] = values[value];
        }

      }
    }

    console.log(addValues);
    addValues['page'] = currentPage;
    addValues['limit'] = currentLimit;
    setBodyData(addValues);
    var _tempString = DateText(start_date, end_date);
    console.log(_tempString);
    setDateString(_tempString);
    getOrders(addValues, addValues);
  };

  const generateTrf = (record) => {
    ;
    console.log(selected['keys']);
    var apiUrl = `${API_URI}/admin/v1/generate_trf`;
    setFetching(true);
    var filterData = { "truck": record['truck'] };
    console.log(filterData);
    let authorization = localStorage.getItem("jwtToken");

    setBodyData(filterData);
    console.log(apiUrl);

    axios.get(apiUrl, { params: filterData, headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);
        debugger
        if ("data" in response && "data" in response.data) {

          setFetching(false);
          getOrders();
        }
      })
      .catch(async (error) => {
        ;
        debugger;
        await errorHandler(error);
        setFetching(false);
        sendNotification(error.response.data.error_message
          , '', 'error');
      })

  }

  return (
    <Admin sidenav={props.nav}>
      <PageHeader
        extra={[
          // <RangePicker size="small" defaultValue={[moment('2019-09-03', dateFormat), moment('2019-11-22', dateFormat)]} />
        ]}
      >

        {/* <Row gutter={16}>
          <Col span={24}> */}
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }} >
          <SimpleForm form={form} layout="verticall" inputs={inputs} row={true} initialValues={{ 'date': moment() }} />
          <p style={{ marginLeft: 15, lineHeight: '24px', fontWeight: 600, fontSize: '12px', display: 'inline-block', marginTop: 4, color: '#07A197' }}> {dateString}</p>
        </div>
        <Button
          type="primary"
          htmlType="submit"
          onClick={onFormFinish}
          style={{ marginLeft: '2%' }}
        >
          Filter Transactions
        </Button>

        {/* </Col>
        </Row> */}
        <Divider />
      </PageHeader>
      {
        !isFetching &&
        <div>

          <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
            <div>
              <p style={{ fontWeight: 600 }}>On Hold</p>

              <p>Please approve uploaded orders by clicking add to queue.</p>
            </div>
            <EditableTable
              rowKey="truck"
              rowClassName={() => 'editable-row'}
              columns={columns}
              dataSource={shipments}
              loading={isFetching}
              pagination={{
                showSizeChanger: true,
                defaultPageSize: currentLimit,
                defaultCurrent: 1,
                total: total,
                onChange: handlePageChange,
              }}
              size="small"
              scroll={{ x: '500px', y: '200px' }}
            />

          </div>
          <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>

            <QueueList></QueueList>
          </div>
        </div>
      }

      {
        isFetching && <div style={{
          flex: 1, width: "100%",
          height: 500 + "px",
          backgroundColor: "#f0f2f5",
          textAlign: 'center',
          justifyContent: 'center',
          verticalAlign: 'center',
          padding: 150 + "px"
        }}><Spin></Spin></div>
      }
    </Admin >
  );
}

export default TruckList;
