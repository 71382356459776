import React from 'react';
import { Button, notification } from 'antd';
import { SmileOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import exitIcon from '../assets/exit-icon.png';
const sendNotification = (title, description, type, duration = 5) => {
    var icon = <ExclamationCircleOutlined style={{ color: '#aaaaaa' }} />;
    if (type == "success") {
        icon = <CheckOutlined style={{ color: '#22bb33' }} />;
    }
    else if (type == "error" || type == "failed") {
        icon = <ExclamationCircleOutlined style={{ color: '#bb2124' }} />;
    }
    else if (type == "exit_error") {
        icon = <img height={20} width={20} src={exitIcon} style={{ color: '#bb2124' }} />;
    }
    notification.open({
        message: <b>{title}</b>,
        description: description,
        icon: icon,
        duration: duration
    });
};

export default sendNotification;
