import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import axios from '../../../../utils/custom-axios';
import { API_URI } from '../../../../config';
import sendNotification from '../../../../utils/sendNotification';
import errorHandler from '../../../../utils/errorHandler';
export function LoadingList(props) {
  const [isFetching, setFetching] = useState(false);
  const [loadinglistData, setloadinglistData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
  }, []);

  useEffect(() => {
  }, []);
  async function sendMail() {
    let data = '',
      apiUrl = `${API_URI}/admin/v1/loading_list?truck=${props.truck}`;
    setFetching(true);

    let authorization = localStorage.getItem("jwtToken");
    console.log(apiUrl);
    var postData = {};
    await axios.post(apiUrl, postData, { headers: { 'Authorization': authorization, 'Content-type': 'application/json;  charset=utf-8' } })
      .then((response) => {

        console.log(response);

        if ("data" in response && "data" in response.data) {
          setCurrentPage(response.data.data.page);
          setTotal(response.data.data.total_count);
          setloadinglistData(response.data.data.mail_list);
          setFetching(false);
          sendNotification('Mail sent successfully', '', 'success');
          setTimeout(function () {
            window.location.reload(1);
          }, 1000);
        }
      })
      .catch(async (error) => {

        await errorHandler(error);
        setFetching(false);
        sendNotification('Failed to fetch Txns', '', 'error');
      })
  }
  const togglePopup = props.handleClose;
  const store = props.data;

  return (

    <div style={{ height: '120%', maxHeight: '120%' }} className="status-popup-box">

      <div style={{ textAlign: 'center' }} className="loading-list-box">

        <div style={{ alignContent: "center", alignItems: "center" }}>
          <h2 >Send Loading List</h2>

          <h3 >Sending loading list to </h3>
          {console.log(props.truck)}
        </div>
        {
          store.map((emails) => (
            <p>{emails}</p>
          ))
        }
        <p>Last loading list sent on </p>
        {/* //cancel butoon css
              color:'var(--primary-color)',borderColor:'var(--primary-color)',width:'30%'  */}
        {/* // primary button css 
              marginLeft:'2%' ,width:'30%'}} */}

        <Button ghost onClick={() => { togglePopup() }} style={{
          color: 'var(--primary-color)', borderColor: 'var(--primary-color)',
          padding: '5px 20px', width: 90, height: '35px', marginTop: '2%',
        }}>Cancel</Button>
        <Button type="primary" onClick={() => { sendMail() }} style={{ marginLeft: '3%', padding: '5px 24px', height: '35px', marginTop: '2%', }}>Send Loading List</Button>
      </div>
    </div>

  );

}
